import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@arkestro/arkestro-design-system';

import { attachTrackingAnalytics } from '@services/SegmentService';
import { VENDOR_WELCOME_VIEWED, VENDOR_OVERVIEW_STEP_VIEWED } from '@utils/analytics_constants';
import FloatingPanel from '@components/on_board/FloatingPanel/FloatingPanel';
import Footer from '@components/on_board/Footer/Footer';
import { VendorOnboardContext } from '@components/on_board/vendor/VendorOnboardProvider/VendorOnboardProvider';

import WelcomeCarousel from './WelcomeCarousel/WelcomeCarousel';
import WelcomeScreen from './WelcomeScreen/WelcomeScreen';

const COL_SIZE_HASH_MAP = {
  welcome: { xs: 24, sm: 16, md: 14, lg: 12, xl: 8 },
  carousel: { sm: 22, md: 20, lg: 20, xl: 12 }
};

const COMPLETE_BTN_STYLE = {
  position: 'absolute',
  textAlign: 'center',
  bottom: -65,
  left: '50%',
  width: 200,
  marginLeft: -100
};

const VendorWelcomeGuide = () => {
  const { t } = useTranslation('common');

  useEffect(() => attachTrackingAnalytics(VENDOR_WELCOME_VIEWED), []);

  const [isLastStep, setIsLastStep] = useState(false);

  const handleChange = (stepNum, stepCount) => {
    attachTrackingAnalytics(VENDOR_OVERVIEW_STEP_VIEWED, { 'Step Number': stepNum + 1 }); // 0 index
    setIsLastStep(stepNum === (stepCount - 1));
  };

  return (
    <VendorOnboardContext.Consumer>
      {({ currentWelcomeStep, currentStepName, updateOnboardStep, setProperty }) => (
        <FloatingPanel colSizes={COL_SIZE_HASH_MAP[currentWelcomeStep]}>
          <div className='' id='vendor-welcome-wizard'>
            {currentWelcomeStep === 'welcome' && <WelcomeScreen setWelcomeStep={setProperty} />}
            {currentWelcomeStep === 'carousel' && (
              <WelcomeCarousel onChange={handleChange} />
            )}
          </div>
          <Footer onSkip={isLastStep ? undefined : () => updateOnboardStep(currentStepName)} />
          {isLastStep && (
            <div style={COMPLETE_BTN_STYLE}>
              <Button onClick={() => updateOnboardStep(currentStepName)} type="primary">
                {t('general.complete')}
              </Button>
            </div>
          )}
        </FloatingPanel>
      )}
    </VendorOnboardContext.Consumer>
  );
};

export default VendorWelcomeGuide;
