import PropTypes from 'prop-types';
import { Button } from '@arkestro/arkestro-design-system';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import style from './ScreenIndicators.module.scss';

const ScreenIndicators = ({ activeStep, stepCount, setCurrentStep }) => {
  const isFirst = activeStep === 0;
  const isLast = activeStep === (stepCount - 1);
  const stepArrForLoop = new Array(stepCount).fill();

  const navigate = direction => {
    if (isFirst && direction === 'back') return;
    if (isLast && direction === 'forward') return;

    const nextStep = direction === 'forward' ? (activeStep + 1) : (activeStep - 1);

    setCurrentStep(nextStep);
  };

  return (
    <div className={style.wrapper}>
      <Button
        type='link'
        style={{ visibility: isFirst ? 'hidden' : 'visible' }}
        onClick={() => navigate('back')}
        icon={<LeftCircleOutlined />}
      />
      <div className={style.indicators}>
        {stepArrForLoop.map((_, i) => {
          const stepStyle = i === activeStep ? style.active : {};

          return (
            <div
              key={i}
              className={`${style.indicator} ${stepStyle}`}
              onClick={() => setCurrentStep(i)}
            />
          );
        })}
      </div>
      <Button
        type='link'
        style={{ visibility: isLast ? 'hidden' : 'visible' }}
        onClick={() => navigate('forward')}
        icon={<RightCircleOutlined />}
      />
    </div>
  );
};

ScreenIndicators.propTypes = {
  activeStep: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired
};

export default ScreenIndicators;
