import { Row } from '@arkestro/arkestro-design-system';
import CompanyAssignmentWizard from './vendor/CompanyAssignmentWizard/CompanyAssignmentWizard';
import VendorProfileFields from './vendor/VendorProfileFields/VendorProfileFields';
import VendorWelcomeGuide from './vendor/VendorWelcomeGuide/VendorWelcomeGuide';
import VendorOnboardProvider, { VendorOnboardContext } from './vendor/VendorOnboardProvider/VendorOnboardProvider';

const VendorOnBoard = () => (
  <VendorOnboardProvider>
    <VendorOnboardContext.Consumer>
      {({ currentStepName, updateOnboardStep, progress }) => {
        const passedProps = { currentStepName, updateOnboardStep, progress };

        return (
          <div id='vendor-onboard-wrapper'>
            <Row align='middle' justify='center'>
              {currentStepName === 'intro' && <VendorProfileFields {...passedProps} />}
              {currentStepName === 'company_assignment' && <CompanyAssignmentWizard {...passedProps} />}
              {currentStepName === 'vendor_welcome' && <VendorWelcomeGuide {...passedProps} />}
            </Row>
          </div>
        );
      }}
    </VendorOnboardContext.Consumer>
  </VendorOnboardProvider>
);

export default VendorOnBoard;
