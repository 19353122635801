// TODO: Update the actual Types
class GraphQLResponse {
  response: any;

  constructor (response: any) {
    this.response = response;
  }

  normalizeConnection (key: string): any[] {
    return this.response[key].edges.map(edge => edge.node);
  }
}

export default GraphQLResponse;
