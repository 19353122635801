import useTranslation from 'next-translate/useTranslation';
import { useRef, useState, useEffect, useContext } from 'react';
import { Tooltip } from '@arkestro/arkestro-design-system';
import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { UserContext } from 'components/providers/UserProvider/UserProvider';
import style from './SettingsDisplay.module.scss';

const UserInfoDisplay = ({ rightAlign }) => {
  const { t } = useTranslation('common');
  const user = useContext(UserContext);

  let userType = user.category;

  if (userType === 'vendor') userType = t('tooltip.user_info.vendor');
  if (userType === 'buyer') userType = t('tooltip.user_info.buyer');

  const displayUserType = _capitalize(userType);
  const displayName = user.first_name ? user.name : user.email;
  const displayCompany = `${displayUserType} ${t('tooltip.user_info.of')} ${user.company_name}`;
  const nameRef = useRef(null);
  const companyRef = useRef(null);
  const [titleName, setTitleName] = useState();
  const [titleCompany, setTitleCompany] = useState();
  const className = `user-info-display ${style.userDisplay} ${!rightAlign ? style.flexStart : style.flexEnd}`;

  useEffect(() => {
    if (nameRef?.current?.offsetWidth < nameRef?.current?.scrollWidth) {
      setTitleName(displayName);
    }
  }, [nameRef?.current, displayName]);
  useEffect(() => {
    if (companyRef?.current?.offsetWidth < companyRef?.current?.scrollWidth) {
      setTitleCompany(displayCompany);
    }
  }, [companyRef?.current, displayCompany]);

  return (
    <div className={className}>
      <div ref={nameRef} className={style.displayName}>
        <Tooltip title={titleName} placement='bottomLeft'>
          {displayName}
        </Tooltip>
      </div>
      <div ref={companyRef} className={style.displayCompany}>
        <Tooltip title={titleCompany} placement='bottomLeft'>
          {displayCompany}
        </Tooltip>
      </div>
    </div>
  );
};

UserInfoDisplay.propTypes = {
  rightAlign: PropTypes.bool
};

UserInfoDisplay.defaultProps = {
  rightAlign: false
};

export default UserInfoDisplay;
