import { ROUTE_CHANGE_ABORT_ERROR } from '@components/shared/UnsavedChangesDialog/UnsavedChangesDialog';
import {
  defaultIntegrations,
  defaultStackParser,
  makeFetchTransport
} from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

class SentryService {

  static configureUser = user => {
    if (!user) return;
    if (process.env.NEXT_PUBLIC_NODE_ENV !== 'production') return;

    Sentry.configureScope(scope => {
      scope.setUser({
        id: user.id,
        username: `${user.first_name} ${user.last_name}`,
        email: user.email,
        ip_address: user.current_sign_in_ip,
        is_superadmin_session: user.impersonating,
        superadmin_user: user?.true_user?.email,
      });
    });
  }

  static initialize = () => {
    if (process.env.NEXT_PUBLIC_NODE_ENV !== 'production') return;

    Sentry.init({
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      environment: process.env.NEXT_PUBLIC_ARKESTRO_ENV,
      transport: makeFetchTransport,
      stackParser: defaultStackParser,
      integrations: [
        ...defaultIntegrations,
        new RewriteFrames({
          iteratee: frame => {
            const newFrame = frame;

            // this line decodes square brackets in the filename
            // for example %5Bvar%5D.js will become [var].js
            newFrame.filename = decodeURI(frame.filename);
            return newFrame;
          },
        }),
      ],
      ignoreErrors: [
        'TypeError: Failed to fetch',
        ROUTE_CHANGE_ABORT_ERROR
      ]
    });
  }
}

export default SentryService;
