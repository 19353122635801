import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { PALM } from '@utils/images';
import { Button, Col, Row } from '@arkestro/arkestro-design-system';

const WAVE_STYLE = {
  height: 40,
  verticalAlign: 'bottom'
};

const WelcomeScreen = ({ setWelcomeStep }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Row>
        <Col xs={24}>
          <span className='title' style={{ marginRight: 10 }}>
            {t('onboarding.supplier.welcome_title')}
          </span>
          <img src={PALM} alt={t('onboarding.supplier.welcome_wave')} style={WAVE_STYLE} />
          <p className='subtitle' style={{ marginTop: 30 }}>
            {t('onboarding.supplier.before_you_dive_in')}
          </p>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 30 }} justify='center'>
        <Col xs={24} sm={12}>
          <Button
            onClick={() => setWelcomeStep({ currentWelcomeStep: 'carousel' })}
            block
            type="primary"
          >
            {t('onboarding.supplier.lets_begin')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

WelcomeScreen.propTypes = {
  setWelcomeStep: PropTypes.func.isRequired
};

export default WelcomeScreen;
