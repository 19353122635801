import PropTypes from 'prop-types';
import { Component, createContext } from 'react';
import moment from 'moment';
import { withRouter } from 'next/router';
import withTranslation from 'next-translate/withTranslation';
import { submitVendorDocument, updateVendorDocument } from '@api/RequestApi';
import { deleteVendorDocument } from '@api/VendorDocumentsApi';
import { addConsentBidDocument } from '@api/BidDocumentApi';
import { alertMessage } from '@utils/common';

/* Full Changes Configuration Object:
  "ui_config" is specific to just the `lots` key within a request ui_config json
  {
    request: { ...attrs },
    ui_config: { ...attrs },
    lots: {
      [lot_id]: {
        ...attrs,
        custom_column_headers: {},
        line_items: {
          [line_item_id]: {
            ...attrs,
            custom_column_items: {}
          }
        }
      }
    }
  }
*/
export const DEFAULT_LOTS_CHANGES = { request: {}, ui_config: {}, lots: {} };

const MIN_FETCH_INTERVAL = 30000; // 30 seconds

export const RequestContext = createContext({
  setRequestProperty: _property => { },
  zeroBaselines: [],
  enableFilteredLineItemsByBaseline: false,
  id: 0,
  name: '',
  lots: [],
  async_loads: [],
  isProcessingBaselinesFromPusher: false,
  enable_line_item_answers_in_rank_feedback: false,
  bidding_event: {
    rounds: [],
    event_format: '',
  },
  company: {},
  company_date_part_order: '',
  status: '',
  ui_config: {
    lots: {
      show_logistics_charge: false,
      enable_regional_baseline: false
    },
  },
  has_survey_responses: false,
  expected_coverage_percent: null,
  inProgress: false,
  company_line_item_column_overrides: {},
  currency: 'USD',
  state: '',
  lotsChanges: DEFAULT_LOTS_CHANGES,
  business_unit_id: null,
  award_scenarios: [],
  company_name: null,
  scoring_survey: {},
  orgs_fully_completed_scoring_survey_count: 0,
  shortlisted_vendor_org_count: 0,
  vendor_organizations: [],
  vendors: [],
});

class RequestProvider extends Component {
  constructor (props) {
    super(props);

    this.id = props.router.query.id;

    this.state = {
      id: this.id,
      isUploading: false,
      pusherChannel: props.pusher.subscribe(`request-${this.id}`),
      isQueuingLots: false,
      lotsChanges: DEFAULT_LOTS_CHANGES,
      isRecentlySavedDraft: false, // For Supplier Quote Submission "Saved"
      lastSavedDraftAt: null, // For Supplier Quote Submission "Last Saved"
      enableFilteredLineItemsByBaseline: false,
      zeroBaselines: [],
      isProcessingBaselinesFromPusher: false,
      lots: [],
      async_loads: [],
      award_scenarios: [],
    };

    this.setRequestProperty = this.setRequestProperty.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);
    this.handleOneUpload = this.handleOneUpload.bind(this);
    this.handleVendorDocumentDelete = this.handleVendorDocumentDelete.bind(this);
    this.handleVendorDocumentUpdate = this.handleVendorDocumentUpdate.bind(this);
    this.handleAddConsentToDocument = this.handleAddConsentToDocument.bind(this);
  }

  componentWillUnmount () {
    this.isSubscribed = false;
    this.state.pusherChannel.unsubscribe();
  }

  // Async call back is optional. Needed when need to make sure a state update has happened before calling a function
  setRequestProperty (property, asyncCallBack = () => null) {
    this.setState({ ...property }, () => asyncCallBack());
  }

  syncDraftSaveStatus = () => {
    this.setState({
      isRecentlySavedDraft: true,
      lastSavedDraftAt: moment(),
    });

    setTimeout(() => {
      this.setState({
        isRecentlySavedDraft: false,
      });
    }, 1000);
  }

  /**
   * Determines if child component should fetch data again based on its last fetch time. This is a more performant
   * mechanism to update data on each tab or component mount, while blocking multiple requests within a set interval.
   * @param {String} type Component type from Request Details trying to fetch new data
   * @param {Number} interval Number of milliseconds between fetches
   * @param {Boolean} forceFetch Force the fetch, like if a pusher event occurs that requires an update.
   * @return {Boolean}
   */
  shouldFetch (type, interval = MIN_FETCH_INTERVAL, forceFetch = false) {
    if (forceFetch) return true;

    const currentTime = Date.now();
    const typeFetchedAt = this.state[`${type}FetchedAt`] || (currentTime - interval);
    const shouldTypeFetch = currentTime - typeFetchedAt >= interval;

    // Update this type's last fetch time
    if (shouldTypeFetch) this.setState({ [`${type}FetchedAt`]: currentTime });

    return shouldTypeFetch;
  }

  /**
   * As a Supplier only (for now) Submit a document as new or an update to existing doc
   * @param {Binary} file
   * @param {Number} documentId Bid Document id used if "updating" an existing doc
   */
  handleOneUpload (binaryTypesAllowed, file, documentId) {
    if (!file) {
      return Promise.resolve({
        success: false,
        status: 422, // unprocessable entity
        errors: [this.props.i18n.t('errors.no_file_attached')]
      });
    }

    return (
      submitVendorDocument(this.t, binaryTypesAllowed, file, this.state.id, documentId)
        .then(result => {
          if (result.success) {
            alertMessage(this.props.i18n.t('messages.saved_file'), 'success', 5);
            const bidDocument = result.document.bid_documents;

            let newBidDocs = this.state.bid_documents || [];

            // Updating existing document - #update returns bid_document and not bid_documents
            if (bidDocument) {
              const existingDocIndex = newBidDocs.findIndex(doc => doc.id === bidDocument.id);

              newBidDocs[existingDocIndex] = bidDocument;
            } else {
              newBidDocs = [...newBidDocs, result.bid_documents]; // bid_documents is {} and not []
            }

            this.setState({
              bid_documents: newBidDocs,
              isUploading: false
            });
            this.setRequestProperty({ missing_required_documents: result.missing_required_documents });
          } else {
            const errorMsg = result.errors && result.errors[0] || this.props.i18n.t('errors.uploading_file');

            alertMessage(errorMsg, 'error', 10);

            this.setState({ isUploading: false });
          }

          // Need ability to know what happened after uploading
          return Promise.resolve(result);
        })
    );
  }

  /**
   * As a Supplier only add consent to a document
   * @param {number} documentId The id of the bid document that the supplier is consenting to
   */
  handleAddConsentToDocument (documentId) {
    const data = {
      requestId: this.state.id,
      id: documentId,
    };

    return (
      addConsentBidDocument(data)
        .then(result => {
          if (result.success) {
            this.setState({
              bid_documents: result.bid_documents,
              missing_required_documents: result.missing_required_documents,
              not_consented_and_blocked: result.not_consented_and_blocked
            });
          } else {
            const errorMsg = result.errors && result.errors[0] || this.props.i18n.t('errors.add_consent');

            alertMessage(errorMsg, 'error', 10);
          }

          return Promise.resolve(result);
        })
    );
  }

  async handleVendorDocumentUpdate (
    t,
    binaryTypesAllowed,
    documentId,
    attributes,
    userMessage = 'Document updated!'
  ) {
    const request = this.state;
    const response = await updateVendorDocument(
      t,
      binaryTypesAllowed,
      request.id,
      documentId,
      attributes
    );

    if (response.success) {
      alertMessage(userMessage);
      this.setState({ bid_documents: response.bid_documents });
    } else {
      alertMessage('Error', 'error', 10);
    }
    return response;
  }

  handleVendorDocumentDelete (documentId) {
    const request = this.state;

    return deleteVendorDocument(request.id, documentId)
      .then(response => {
        if (response.success) {
          alertMessage(this.props.i18n.t('messages.file_deleted'));

          this.setState({ bid_documents: response.bid_documents });
          this.setRequestProperty({ missing_required_documents: response.missing_required_documents });
        } else {
          alertMessage(this.props.i18n.t('errors.deleting_file'), 'error', 10);
        }

        return Promise.resolve(response);
      });
  }

  render () {
    return (
      <RequestContext.Provider
        value={{
          ...this.state,
          setRequestProperty: this.setRequestProperty,
          syncDraftSaveStatus: this.syncDraftSaveStatus,
          shouldFetch: this.shouldFetch,
          handleVendorDocumentUpdate: this.handleVendorDocumentUpdate,
          handleVendorDocumentDelete: this.handleVendorDocumentDelete,
          handleOneUpload: this.handleOneUpload,
          handleAddConsentToDocument: this.handleAddConsentToDocument
        }}
      >
        {this.props.children}
      </RequestContext.Provider>
    );
  }
}

RequestProvider.propTypes = {
  children: PropTypes.node.isRequired,
  i18n: PropTypes.shape().isRequired,
  router: PropTypes.shape(),
  pusher: PropTypes.shape().isRequired
};

export default withRouter(withTranslation(RequestProvider, 'common'));
