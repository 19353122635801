import { BASE_CONNECTION_INFO, createClient } from '@utils/graphql';

export const bidRequestAutomatedQuery = args => createClient().query({
  bidRequests: [
    args,
    {
      ...BASE_CONNECTION_INFO,
      unscopedCount: true,
      edges: {
        cursor: true,
        node: {
          automated: true,
        }
      },
    },
  ],
});
