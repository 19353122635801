import PropTypes from 'prop-types';
import style from './SideMenu.module.scss';

const Backdrop = ({ collapsed }) => {
  if (collapsed) return null;

  return <div id='side-menu-backdrop' className={style.backdrop} />;
};

Backdrop.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default Backdrop;
