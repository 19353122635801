const ASCENDING_GROUP = ['asc', 'ascend'];
const DESCENDING_GROUP = ['desc', 'descend'];

export const alphabeticalSort = (a, b, attr, dir = 'asc', forAntDTable = false) => {
  const isAscending = ASCENDING_GROUP.includes(dir);

  let num = 0;

  let stopEarly = false;

  // If using this inside non-remote AntD Table columns object, they flip the order within their internal state
  // for the table, so their component expects everything sorted "ascending". That being said, we need to invert
  const antNumNormalizer = forAntDTable && !isAscending ? -1 : 1;

  // Don't try and sort if record doesn't exist
  // Example: Passing "record.xyz" instead of just "record" where "xyz" may not be on each record payload
  if (!a || a === '') {
    num = isAscending ? 1 : -1;
    stopEarly = true;
  } else if (!b || b === '') {
    num = isAscending ? -1 : 1;
    stopEarly = true;
  } else if (!a[attr] || a[attr] === '') {
    num = isAscending ? 1 : -1;
    stopEarly = true;
  } else if (!b[attr] || b[attr] === '') {
    num = isAscending ? -1 : 1;
    stopEarly = true;
  }

  if (stopEarly) return num * antNumNormalizer;

  // 'ascend', 'descend' are defaults provided by Ant D so we should handle them
  const aString = a[attr].toString().toLowerCase();
  const bString = b[attr].toString().toLowerCase();

  if (aString === bString) {
    num = 0;
  } else if (isAscending) {
    num = aString < bString ? -1 : 1;
  } else {
    // Descending
    num = aString < bString ? 1 : -1;
  }

  return num * antNumNormalizer;
};

export const numericalSort = (a, b, attr, dir = 'asc', forAntDTable = false, ignoreZero) => {
  const isAscending = ASCENDING_GROUP.includes(dir);

  let num = 0;

  let stopEarly = false;

  // If using this inside non-remote AntD Table columns object, they flip the order within their internal state
  // for the table, so their component expects everything sorted "ascending". That being said, we need to invert
  const antNumNormalizer = forAntDTable && !isAscending ? -1 : 1;

  // Don't try and sort if record doesn't exist
  // Example: Passing "record.xyz" instead of just "record" where "xyz" may not be on each record payload
  if (!a) {
    num = isAscending ? 1 : -1;
    stopEarly = true;
  } else if (!b) {
    num = isAscending ? -1 : 1;
    stopEarly = true;
  } else if (!a[attr] && a[attr] !== 0) {
    num = isAscending ? 1 : -1;
    stopEarly = true;
  } else if (!b[attr] && b[attr] !== 0) {
    num = isAscending ? -1 : 1;
    stopEarly = true;
  }

  if (stopEarly) return num * antNumNormalizer;

  // 'ascend', 'descend' are defaults provided by Ant D so we should handle them
  const parsedA = parseFloat(a[attr]);
  const parsedB = parseFloat(b[attr]);

  if (Number.isNaN(parsedA)) return antNumNormalizer;
  if (Number.isNaN(parsedB)) return -1 * antNumNormalizer;

  if (ignoreZero) {
    if (!parsedA) return antNumNormalizer;
    if (!parsedB) return -1 * antNumNormalizer;
  }

  if (parsedA === parsedB) {
    num = 0;
  } else if (isAscending) {
    num = parsedA < parsedB ? -1 : 1;
  } else {
    // Descending
    num = parsedA < parsedB ? 1 : -1;
  }

  return num * antNumNormalizer;
};

/**
 * Wrapper around #numericalSort, for using multiple attributes, attributes need to be in priority order.
 * Could extend to handle setting order for each attribute.
 * @param a {Object} Object with attributes
 * @param b {Object} Another object with attributes
 * @param attrs {Array} List of attributes in array perform, with priority having the lower index, first one highest.
 * @param dir {String} Values like asc, desc, ascend, descend
 * @param forAntDTable {Boolean} Should be set if using for AntD tables, as they reverse the result
 * @param ignoreZero Ignore values where attribute for the object equals 0, effectively moves them to the end.
 */
export const numericalSortWithMultipleAttrs = (
  a,
  b,
  attrs,
  dir = 'asc',
  forAntDTable = false,
  ignoreZero
) => {
  let sortValue = 0;

  while (attrs.length > 0) {
    sortValue = numericalSort(a, b, attrs.shift(), dir, forAntDTable, ignoreZero);
    if (sortValue !== 0) break;
  }
  return sortValue;
};

// 'ascend', 'descend' are defaults provided by Ant D so we should handle them
export const dateSort = (collection, attr, dir = 'desc') => (
  collection.sort((a,b) => {
    if (DESCENDING_GROUP.includes(dir)) return new Date(b[attr]) - new Date(a[attr]);

    return new Date(a[attr]) - new Date(b[attr]);
  })
);

export const byDate = (a, b, attr, dir = 'asc', forAntDTable = false) => {
  const isAscending = ASCENDING_GROUP.includes(dir);

  let num = 0;

  let stopEarly = false;

  // If using this inside non-remote AntD Table columns object, they flip the order within their internal state
  // for the table, so their component expects everything sorted "ascending". That being said, we need to invert
  const antNumNormalizer = forAntDTable && !isAscending ? -1 : 1;

  // Don't try and sort if record doesn't exist
  // Example: Passing "record.xyz" instead of just "record" where "xyz" may not be on each record payload
  if (!a || a === '') {
    num = isAscending ? 1 : -1;
    stopEarly = true;
  } else if (!b || b === '') {
    num = isAscending ? -1 : 1;
    stopEarly = true;
  } else if (!a[attr] || a[attr] === '') {
    num = isAscending ? 1 : -1;
    stopEarly = true;
  } else if (!b[attr] || b[attr] === '') {
    num = isAscending ? -1 : 1;
    stopEarly = true;
  }

  if (stopEarly) return num * antNumNormalizer;

  const aDate = new Date(a[attr]);
  const bDate = new Date(b[attr]);

  if (aDate === bDate) {
    num = 0;
  } else if (isAscending) {
    num = bDate > aDate ? -1 : 1;
  } else {
    // Descending
    num = bDate > aDate ? 1 : -1;
  }

  return num * antNumNormalizer;
};

/**
 *  Simple numeric sort for lists of objects.
 *  @param {array} list - List to be sorted
 *  @param {string} attr - String name of object attribute to sort by
 *  @param {string} dir - Optional direction for sort, 'asc' or 'ascend' for ascending, otherwise descending
 *  @returns {array} Sorted version of the list
 */
export const simpleObjectNumericSort = (
  list,
  attr,
  dir = 'asc',
) => {
  if (list.length <= 0) return [];
  const numericSort = (a, b) => a[attr] - b[attr];
  const sortedList = list.sort(numericSort);

  if (ASCENDING_GROUP.includes(dir)) {
    return sortedList;
  }
  return sortedList.reverse();
};

/**
 *  Organizes a list with filtered items first and remaining items second, each group sorted with provided sorting
 *  function.
 *  @param {array} list - List to be sorted
 *  @param {function} filterFunction - Function to apply the filtering
 *  @param {*} filterOptions - Extra filter options to use as argument for filterFunction
 *  @param {function} sortFunction - sortFunctionFunction to sort filtered and remaining items
 *  @param {*} sortOptions - Extra sorting options to use as argument for sortFunction
*/
export const organizedSort = (list, filterFunction, filterOptions, sortFunction, sortOptions) => {
  const filteredList = list.filter(item => filterFunction(item, filterOptions));
  const remains = list.filter(item => !filterFunction(item, filterOptions));

  return filteredList.sort((a, b) => sortFunction(a, b, sortOptions))
    .concat(remains.sort((a, b) => sortFunction(a, b, sortOptions)));
};

export const bySNo = (a, b) => a.sno - b.sno;

// Use with AntD -- 'ascend' & 'descend' not valid active record query orders
export const RAILS_SORT_ORDER_MAP = {
  ascend: 'asc',
  descend: 'desc',
  asc: 'asc',
  desc: 'desc'
};

// For use w/ THead component
export const TableHeadSortOptions = (incomingOptions, existingOptions) => {
  if (!incomingOptions.sortable) return {};

  let newOrder = 'asc';

  if (incomingOptions.columnKey === existingOptions.columnKey) {
    if (existingOptions.order === 'asc') {
      newOrder = 'desc';
    } else {
      newOrder = undefined;
    }
  }

  const newOptions = { columnKey: incomingOptions.columnKey, order: newOrder };

  if (!newOrder) {
    delete newOptions.columnKey;
    delete newOptions.order;
  }

  return newOptions;
};
