import { Call, ApiUrl, ParseGeneralResponse, getUrlWithPagination } from './ApiUtils';

export const getCompanyVendorProfile = (id, additionalParams) => {
  const url = ApiUrl(`/global/vendor_profiles/${id}?${additionalParams}`);

  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { vendor_profile: 'vendor_profile' },
  });
};

export const getCompanyVendorOrganizationProfile = (id, additionalParams) => {
  let url = ApiUrl(`/global/vendor_organizations/${id}`);

  if (additionalParams) {
    url += `?${additionalParams}`;
  }

  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { vendor_organization: 'vendor_organization' },
  });
};

export function getBasicCompanyVendorList () {
  const url = '/api/v1/company_vendors';

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        company_vendors: 'company_vendors',
      },
    },
  );
}

export function getCompanyVendorList (
  current,
  pageSize,
  sortField,
  sortOrder,
  filters,
  search
) {

  const basePath = '/global/company_vendor_list';

  const url = getUrlWithPagination(basePath, undefined, current, pageSize, sortField, sortOrder, filters, search);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        company_vendors: 'company_vendors',
        pagination: 'pagy'
      },
    },
  );
}

export function getCompanyVendorListForRequest (
  id,
  current,
  pageSize,
  sortField,
  sortOrder,
  filters
) {

  const basePath = '/global/company_vendor_list_for_request';
  const requiredArgs = `id=${id}`;

  const url = getUrlWithPagination(basePath, requiredArgs, current, pageSize, sortField, sortOrder, filters);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        company_vendors: 'company_vendors',
        pagination: 'pagy'
      },
    },
  );
}

export function getCompanyVendorListForVendorOrg (
  id,
  current,
  pageSize,
  sortField,
  sortOrder,
  filters
) {

  const basePath = '/global/company_vendor_list_for_org';
  const requiredArgs = `id=${id}`;

  const url = getUrlWithPagination(basePath, requiredArgs, current, pageSize, sortField, sortOrder, filters);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        company_vendors: 'company_vendors',
        pagination: 'pagy'
      },
    },
  );
}

export function getCompanyVendorOrgList (
  current,
  pageSize,
  sortField,
  sortOrder,
  filters,
  search
) {
  const basePath = '/global/company_vendor_org_list';
  const url = getUrlWithPagination(basePath, undefined, current, pageSize, sortField, sortOrder, filters, search);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        company_vendor_orgs: 'company_vendor_orgs',
        pagination: 'pagy'
      },
    },
  );
}

export function getAllVendorOrgsList () {
  const url = ApiUrl('/global/all_vendor_org_list');

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        company_vendor_orgs: 'all_vendor_orgs'
      },
    },
  );
}

export function updateVendorProfile (data, isFreeEmail) {
  const url = ApiUrl('/vendor_profile_db');

  return Call(
    'post',
    url,
    {
      email: data.email,
      id: data.vendorOrgId,
      first_name: data.firstName,
      last_name: data.lastName,
      job_title: data.jobTitle,
      phone: data.phone,
      is_free_email: isFreeEmail
    },
    ParseGeneralResponse,
    {
      responseDataKeys: {
        vendor_profile: 'vendor_profile'
      },
    },
  );
}

export function updateVendorProfileDetails (data) {
  const url = ApiUrl('/global/update_vendor_profile_details');

  return Call(
    'post',
    url,
    {
      email: data.email,
      notes: data.notes,
      id: data.detailsId,
    },
    ParseGeneralResponse,
    {
      responseDataKeys: {
        vendor_profile: 'vendor_profile'
      },
    },
  );
}
export function updateVendorOrgDetails (data) {
  const url = ApiUrl('/global/update_vendor_org_details');

  return Call(
    'post',
    url,
    {
      vendor_organization_id: data.vendorOrgId,
      notes: data.notes,
      id: data.detailsId,
    },
    ParseGeneralResponse,
    {
      responseDataKeys: {
        vendor_organization: 'vendor_organization'
      },
    },
  );
}

export function updateVendorOrganization (data) {
  const url = ApiUrl('/global/update_vendor_organization');

  return Call(
    'post',
    url,
    {
      email: data.email,
      id: data.vendorOrgId,
      name: data.name,
      description: data.description,
      city: data.city,
      state: data.state,
      state_code: data.stateCode,
      country: data.country,
      country_code: data.countryCode,
      domains: data.domains,
      public_listed: data.publicListed,
      categorization_codes: data.categorizationCodes,
      tags: data.tags,
      phone: data.phone,
      num_of_employees: data.numOfEmployees,
      website: data.website,
      addresses: data.addresses
    },
    ParseGeneralResponse,
    {
      responseDataKeys: {
        vendor_organization: 'vendor_organization'
      },
    },
  );
}

export function attachVendorToOrganization (data) {
  const url = ApiUrl('/global/attach_vendor_to_organization');

  return Call(
    'post',
    url,
    {
      vendor_organization_id: data.vendorOrgId,
    },
    ParseGeneralResponse,
    {
      responseDataKeys: {
        vendor_org: 'vendor_org'
      },
    },
  );
}

export function getVendorOrgRequestInfo (
  id,
  current,
  pageSize,
  sortField,
  sortOrder,
  filters,
  limit
) {

  const basePath = '/global/vendor_org_requests_info';
  const requiredArgs = `id=${id}`;

  let url = getUrlWithPagination(basePath, requiredArgs, current, pageSize, sortField, sortOrder, filters);

  if (limit) url += `&limit=${limit}`;

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        requests_info: 'requests_info',
        pagination: 'pagy',
        average_engagement_score: 'average_engagement_score'
      },
    },
  );
}

export function getVendorRequestInfo (
  email,
  current,
  pageSize,
  sortFields,
  sortOrder,
  filters
) {

  const basePath = '/global/vendor_requests_info';
  const requiredArgs = `email=${email}`;

  const url = getUrlWithPagination(basePath, requiredArgs, current, pageSize, sortFields, sortOrder, filters);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        requests_info: 'requests_info',
        pagination: 'pagy',
      },
    },
  );
}

export const inviteVendors = (emails, subject, message, isTest) => (
  Call(
    'post',
    ApiUrl('/vendors/invite'),
    {
      emails,
      subject,
      message,
      is_test: isTest
    },
    ParseGeneralResponse,
    {
      responseDataKeys: {
        sent_emails: 'sent_emails',
        unsent_emails: 'unsent_emails'
      }
    }
  )
);
