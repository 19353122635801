import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export function updateVendorProfile (data, isFreeEmail) {
  const url = ApiUrl('/vendor_profile_db');

  return Call(
    'post',
    url,
    {
      email: data.email,
      id: data.vendorOrgId,
      first_name: data.firstName,
      last_name: data.lastName,
      job_title: data.jobTitle,
      phone: data.phone,
      is_free_email: isFreeEmail
    },
    ParseGeneralResponse,
    {
      responseDataKeys: {
        vendor_profile: 'vendor_profile'
      },
    },
  );
}
