import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from '@arkestro/arkestro-design-system';
import { UserContext } from '@providers/UserProvider/UserProvider';
import useTranslation from 'next-translate/useTranslation';
import { attachTrackingAnalytics } from '@services/SegmentService';
import { VENDOR_INTRO_DETAILS_VIEWED } from 'utils/analytics_constants';
import FloatingPanel from 'components/on_board/FloatingPanel/FloatingPanel';
import Footer from 'components/on_board/Footer/Footer';
import ProfileFields from 'components/shared/EditProfile/ProfileFields';
import { updateVendorProfile } from 'api/VendorProfileDbApi';

const VendorProfileFields = ({ currentStepName, updateOnboardStep, progress }) => {
  const { t } = useTranslation('common');
  const currentUser = useContext(UserContext);

  useEffect(() => {
    attachTrackingAnalytics(VENDOR_INTRO_DETAILS_VIEWED);
  }, []);

  // Empty strings handle new supplier users that were invited and have null for profile attributes
  const [firstName, setFirstName] = useState(currentUser.first_name || '');
  const [lastName, setLastName] = useState(currentUser.last_name || '');
  const [jobTitle, setJobTitle] = useState(currentUser.job_title || '');

  // Race condition with /me endpoint means we could set the state of these components before we have our
  // current user data resolved.
  useEffect(() => {
    setFirstName(currentUser.first_name);
    setLastName(currentUser.last_name);
    setJobTitle(currentUser.job_title);
  }, [currentUser.id]);

  const handleChange = event => {
    let setFn;

    switch (event.target.name) {
      case 'lastName':
        setFn = setLastName; break;
      case 'jobTitle':
        setFn = setJobTitle; break;
      default:
        setFn = setFirstName; break;
    }

    setFn(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    updateVendorProfile({ email: currentUser.email, firstName, lastName, jobTitle })
      .then(response => {
        if (!response.success) return;

        updateOnboardStep(currentStepName); // updates onboarding state, which advances to next step
      });
  };

  const readOnlyEditingFields = [
    [
      { key: 'email', label: 'email', value: currentUser.email, required: false, errors: [] }
    ]
  ];

  const normalFields = [
    [
      { key: 'firstName', label: t('general.first_name'), value: firstName, required: true, errors: [] },
      { key: 'lastName', label: t('general.last_name'), value: lastName, required: true, errors: [] },
    ],
    [
      { key: 'jobTitle', label: t('general.job_title'), value: jobTitle, required: true, errors: [] }
    ]
  ];

  return (
    <FloatingPanel colSizes={{ xs: 24, sm: 16, md: 14, lg: 12, xl: 8 }}>
      <div className='title'>
        {t('onboarding.supplier.tell_us_about_yourself')}
      </div>

      <form onSubmit={handleSubmit}>
        <ProfileFields
          readOnlyEditingFields={readOnlyEditingFields}
          normalFields={normalFields}
          onChange={handleChange}
        />
        <Row style={{ marginTop: 30 }} justify='center'>
          <Col xs={20} sm={12} lg={9} xl={10}>
            <Button
              htmlType='submit'
              disabled={progress}
              loading={progress}
              type="primary"
              block
            >
              {t('general.continue')}
            </Button>
          </Col>
        </Row>
      </form>
      <Footer />
    </FloatingPanel>
  );
};

VendorProfileFields.propTypes = {
  currentStepName: PropTypes.string.isRequired,
  updateOnboardStep: PropTypes.func.isRequired,
  progress: PropTypes.bool.isRequired
};

export default VendorProfileFields;
