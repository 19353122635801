/* eslint-disable max-len */
export const COMPANY_AVATAR = 'https://bidops-public.s3.amazonaws.com/assets/images/company_avatar.svg';
export const PALM  = 'https://bidops-public.s3.amazonaws.com/assets/images/palm.svg';
export const CREATE_REQUESTS_GRAPHIC = 'https://bidops-public.s3.amazonaws.com/assets/images/onboard-create-requests.svg';
export const COLLABORATE_REQUESTS_GRAPHIC = 'https://bidops-public.s3.amazonaws.com/assets/images/onboard-collaborate.svg';
export const AWARD_GRAPHIC = 'https://bidops-public.s3.amazonaws.com/assets/images/onboard-award.svg';
export const SUPPLIERS_GRAPHIC = 'https://bidops-public.s3.amazonaws.com/assets/images/onboard-manage-suppliers.svg';
export const EVENTS_GRAPHIC = 'https://bidops-public.s3.amazonaws.com/assets/images/onboard-run-events.svg';
export const SMALL_OVAL =' https://bidops-public.s3.amazonaws.com/assets/images/small-oval.svg';
export const SMALL_RECTANGLE = 'https://bidops-public.s3.amazonaws.com/assets/images/small-rounded-rectangle.svg';
export const AWARD_GRAPH_ICON = 'https://bidops-public.s3.amazonaws.com/assets/images/icons-bars-award-graph.svg';
export const CHAT_USERS_ICON = 'https://bidops-public.s3.amazonaws.com/assets/images/chat-users-icons.svg';
export const SEARCH_PROFILES_MAGNIFIER = 'https://bidops-public.s3.amazonaws.com/assets/images/search-profiles-magnifier.svg';
export const THUMBS_UP_GRAPHIC = 'https://bidops-public.s3.amazonaws.com/assets/images/onboard-thumbs-up.svg';
