import _flatMap from 'lodash/flatMap';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import useTranslation from 'next-translate/useTranslation';
import { UserContext } from '@providers/UserProvider/UserProvider';
import { attachTrackingAnalytics } from '@services/SegmentService';
import Footer from 'components/on_board/Footer/Footer';
import { Button, Col, Row, Tooltip } from '@arkestro/arkestro-design-system';
import { getAllVendorOrgsList } from 'api/VendorDBApi';
import { updateVendorProfile } from 'api/VendorProfileDbApi';
import { numericalSort } from 'utils/sorting';
import { doesEmailMatchInDomains, isFreeEmail as checkIfIsFreeEmail, emailParts } from 'utils/email';
import {
  VENDOR_CONFIRM_ORGANIZATION_VIEWED,
  VENDOR_JOINED_ORGANIZATION,
  VENDOR_ORG_ID
} from 'utils/analytics_constants';
import EmailCheckDisplay from './EmailCheckDisplay';

const tooltipText = t => (
  <div>
    <p>
      {t('tooltip.affiliation.yes')}
    </p>
    <p>
      {t('tooltip.affiliation.no')}
    </p>
  </div>
);

const AffiliationConfirmation = ({
  currentStepName,
  updateOnboardStep,
  setProperty,
  vendorOrg,
  setIsFreeEmail,
  isFreeEmail
}) => {
  const { t } = useTranslation('common');
  const currentUser = useContext(UserContext);
  const existingVendorOrgId = currentUser.vendor_profile?.vendor_organization_id;
  const [vendorOrgList, setVendorOrgList] = useState(undefined);
  const didSelectOrg = vendorOrg !== undefined && vendorOrg.id; // Vendor either selected an org or created an org

  // Get list of vendor orgs to do an email match, or return the org the supplier already has
  useEffect(() => {
    attachTrackingAnalytics(VENDOR_CONFIRM_ORGANIZATION_VIEWED);

    // Immediately check if the user email is free email so it's available when confirming organization
    setIsFreeEmail(checkIfIsFreeEmail(currentUser.email));

    if (didSelectOrg) return; // Don't look up vendor org if user telling us which one they want already

    let isSubscribed = true;

    getAllVendorOrgsList()
      .then(response => {
        if (!response.success || !isSubscribed) return;

        const responseOrgList = response.company_vendor_orgs;

        setTimeout(() => {
          if (responseOrgList && responseOrgList.length === 0) {
            setProperty({ currentOrgStep: 'no_org_match' }); // No vendor orgs, so go ahead and advance to create an org
            return;
          }

          setVendorOrgList(responseOrgList);

          // User has org already, so we should check to see if it's name matches its website, which indicates it
          // was temporary and should not appear as an org match option.
          let isTempOrg = false;

          if (existingVendorOrgId) {
            const supplierOrg = responseOrgList.find(org => org.id === existingVendorOrgId) || {};
            const { name, website, description } = supplierOrg;
            const hasNameAndWebsite = !!name && !!website;

            // Handles backwards compatibility from time when we were not storing TLD on org website
            // We would be checking that "fareva" name matches "fareva" website.
            const areNameWebsiteExactMatch = hasNameAndWebsite && (name === website);

            // When a new vendor org is created during shortlisting, we set their description to "Shortlisted".
            // When the supplier onboards, they do not see that description and we will update the description or
            // clear "Shortlisted" from the description when they complete onboarding.
            const isExplicitTempOrg = description === 'Shortlisted';

            isTempOrg = isExplicitTempOrg || areNameWebsiteExactMatch;

            if (isTempOrg) {
              setProperty({ currentOrgStep: 'no_org_match', updateOrgId: supplierOrg.id });
            }
            else {
              setProperty({
                vendorOrg: {
                  id: supplierOrg.id,
                  name: supplierOrg.name
                }
              });
            }

            return;
          }

          // Got the list, now deciding which screen to show
          if (!didSelectOrg && responseOrgList && responseOrgList.length > 0) {
            const vendorOrgDomains = (
              _flatMap(responseOrgList, org => org.vendor_organization_domains)
                .map(domain => domain ? domain.url : '')
                .filter(d => d !== null && d !== undefined && d !== '')
            );

            if (!doesEmailMatchInDomains(currentUser.email, vendorOrgDomains)) {
              // No vendor orgs, so go ahead and advance to create an org
              setProperty({ currentOrgStep: 'no_org_match' });
              return;
            }

            // Determine the match of the user's email against the list of vendor orgs
            // and apply a weight to the match quality and sort by 'heaviest' matched org
            const weightedOrgList = responseOrgList.map(org => {
              const emailSplit = emailParts(currentUser.email);
              const emailFullDomain = `${emailSplit.domain.toLowerCase()}.${emailSplit.tld.toLowerCase()}`;
              const orgDomains = org.vendor_organization_domains.map(domain => domain ? domain.url.toLowerCase() : '')
                .filter(Boolean);

              let weight = 0;

              if (orgDomains.includes(emailFullDomain)) {
                weight = 2.0;
              } else if (doesEmailMatchInDomains(currentUser.email, orgDomains)) {
                weight = 1.0;
              }

              return {
                organization: org,
                weight
              };
            }).sort((a, b) => numericalSort(a, b, 'weight', 'desc'));

            const matchedVendorOrg = weightedOrgList[0].organization;

            setProperty({
              vendorOrg: {
                id: matchedVendorOrg.id,
                name: matchedVendorOrg.name
              }
            }); // set the vendor org that matches to context
          }
        }, 3000);
      });

    return () => { isSubscribed = false; };
  }, []);

  const confirmOrganization = () => {
    if (!vendorOrg || !vendorOrg.id) return;

    attachTrackingAnalytics(VENDOR_JOINED_ORGANIZATION, { [VENDOR_ORG_ID]: vendorOrg.id });

    // Attach vendor org to current vendor user's profile
    updateVendorProfile({ email: currentUser.email, vendorOrgId: vendorOrg.id }, isFreeEmail)
      .then(response => {
        if (!response.success) return;
        updateOnboardStep(currentStepName); // updates onboarding state, which advances to next step
      });
  };

  if ((!didSelectOrg && !vendorOrgList) || !vendorOrg) return <EmailCheckDisplay />; // Still fetching vendor org list

  return (
    <>
      <Row>
        <Col xs={24}>
          <span className='title' style={{ marginRight: 10 }}>
            {t('tooltip.affiliation.are_you_affiliated')} <strong>{vendorOrg.name}</strong>?
          </span>
          <Tooltip placement="bottom" title={tooltipText(t)} overlayStyle={{ minWidth: 300 }}>
            <InfoCircleFilled />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 30 }} justify='center'>
        <Col xs={24} sm={10}>
          <Button
            onClick={confirmOrganization}
            block
            type="primary"
          >
            {t('general.yes')}
          </Button>
        </Col>
        <Col xs={24} sm={10}>
          <Button
            onClick={() => setProperty({ currentOrgStep: 'no_org_match', updateOrgId: null })}
            block
            type="primary"
          >
            {t('general.no')}
          </Button>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

AffiliationConfirmation.propTypes = {
  updateOnboardStep: PropTypes.func.isRequired,
  setProperty: PropTypes.func.isRequired,
  currentStepName: PropTypes.string.isRequired,
  vendorOrg: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    name: PropTypes.string
  }),
  setIsFreeEmail: PropTypes.func.isRequired,
  isFreeEmail: PropTypes.bool
};

AffiliationConfirmation.defaultProps = {
  vendorOrg: undefined,
  isFreeEmail: true
};

export default AffiliationConfirmation;
