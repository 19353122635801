import { ApiUrl, Call, ParseGeneralResponse } from './ApiUtils';

export function deleteVendorDocument (requestId, id) {
  const url = ApiUrl(`/bid_requests/${requestId}/vendor_documents/${id}`);

  return Call(
    'delete',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { bid_documents: 'bid_documents', missing_required_documents: 'missing_required_documents' }
    }
  );
}
