import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { attachTrackingAnalytics } from '@services/SegmentService';
import { VENDOR_SKIPPED_OVERVIEW_STEPS } from 'utils/analytics_constants';
import { safeKeyPress } from 'utils/accessibility';
import { RightOutlined } from '@ant-design/icons';

const WRAPPER_STYLE = {
  textAlign: 'center',
  position: 'relative',
  marginTop: 40
};

const PRIVACY_WRAPPER_STYLE = {
  color: '#c0c0c0',
  fontSize: 14,
  lineHeight: 1.6,
  fontWeight: 400
};

const SKIP_STYLE = {
  fontSize: 12,
  fontWeight: 600,
  color: '#000', // $theme-text-color
  position: 'absolute',
  right: 0,
  bottom: 4,
  cursor: 'pointer'
};

const PRIVACY_URL = 'https://arkestro.com/privacy-policy';
const TERMS_URL = 'https://arkestro.com/terms-of-use';

const Footer = ({ showPrivacyTerms, onSkip }) => {
  const { t } = useTranslation('common');
  const handleSkip = () => {
    attachTrackingAnalytics(VENDOR_SKIPPED_OVERVIEW_STEPS, []);

    if (onSkip) onSkip();
  };

  return (
    <div style={WRAPPER_STYLE}>
      {showPrivacyTerms && (
        <div style={PRIVACY_WRAPPER_STYLE}>
          <a href={PRIVACY_URL} rel='noopener noreferrer' target='_blank' style={{ color: '#c0c0c0' }}>
            {t('general.privacy_policy')}
          </a>
          <span>  |  </span>
          <a href={TERMS_URL} rel='noopener noreferrer' target='_blank' style={{ color: '#c0c0c0' }}>
            {t('general.terms_of_use')}
          </a>
        </div>
      )}
      {onSkip && (
        <div
          style={SKIP_STYLE}
          onClick={handleSkip}
          onKeyPress={e => safeKeyPress(e, handleSkip)}
          tabIndex={0}
          role='button'
        >
          {t('general.capitalized.skip')}
          <RightOutlined style={{ fontSize: 12, marginLeft: 10 }} />
        </div>
      )}
    </div>
  );
};

Footer.propTypes = {
  showPrivacyTerms: PropTypes.bool,
  onSkip: PropTypes.func
};

Footer.defaultProps = {
  showPrivacyTerms: true,
  onSkip: undefined
};

export default Footer;
