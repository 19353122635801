import axios from 'axios';
import { refreshAccessToken } from 'api/AuthApi';
import RedirectService from './RedirectService';
import Routes from './Routes';

class AxiosService {
  static setup = () => {
    axios.defaults.baseURL = Routes.BASE_PLATFORM_URL;
    axios.defaults.withCredentials = true;

    // Attempt to refresh the users access token for authorization
    axios.interceptors.response.use(
      response => response,
      async error => AxiosService.handleResponseError(error)
    );
  }

  static handleResponseError = async error => {
    const originalRequest = error.config;
    const { url: originalPath } = originalRequest;

    let shouldAttemptRetry = RedirectService.wasUnauthorizedError(error);
    const hasRetried = !!originalRequest._retry;
    const isRefreshPath = Routes.REFRESH_IGNORE_PATHS.includes(originalPath);

    // If the original request was trying to sign in when it errored, or was a refresh attempt,
    // don't try and do anything with it so we can either surface errors to the UI or avoid
    // infinite loop on /refresh endpoint.
    if (isRefreshPath) shouldAttemptRetry = false;

    // ARKESTRO_CLIENT_AUDIT TODO Handle redirect when account is locked without infinite loop.
    // if (RedirectService.wasLockedError(error)) {
    //   RedirectService.redirectToLogin();
    //   return;
    // }

    if (shouldAttemptRetry && !hasRetried) {
      originalRequest._retry = true;

      const refreshMyToken = await refreshAccessToken();

      // If token error when refreshing, this user needs to be redirected to the login page.
      if (refreshMyToken.data) {
        const {
          jwt: accessToken,
          refresh_token: refreshToken
        } = refreshMyToken.data;

        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        originalRequest.headers['jwt'] = accessToken;
        originalRequest.headers['refresh_token'] = refreshToken;

        return axios(originalRequest);
      }

      if (!RedirectService.isAuthenticationPage() && !RedirectService.isPublicPage()) {
        RedirectService.redirectToLogin();
      }
    }

    // Should always be returning some form of a promise at the end of this otherwise response could be
    // undefined in an async call.
    return Promise.reject(error);
  }
};

export default AxiosService;
