import { Call, ApiUrl, ParseGeneralResponse, getUrlWithPagination } from './ApiUtils';
import AppConfig from '../config/AppConfig';

export const VENDOR_STEPS = {
  intro: 'company_assignment',
  company_assignment: 'vendor_welcome',
  vendor_welcome: 'completed',
  vendor_training_video: 'completed'
};

export function setOnBoardStep (step) {
  const url = ApiUrl('/users/on_board_step');

  return Call(
    'put',
    url,
    { step },
    ParseGeneralResponse,
    {
      responseDataKeys: { step: 'step' },
    },
  );
}

export function fetchUsers (page = 1, perPage = AppConfig.perPage, sortField, sortOrder, filters, search) {
  const baseUrl = '/users';
  const url = getUrlWithPagination(baseUrl, undefined, page, perPage, sortField, sortOrder, filters, search);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { users: 'users', pagination: 'pagy' } },
  );
}

export const getUser = id => {
  const url = ApiUrl(`/users/${id}`);

  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { user: 'user' },
  });
};

export function saveUser (isNew, data) {
  const url = isNew ? ApiUrl('/users') : ApiUrl(`/users/${data.id}`);
  const method = isNew ? 'post' : 'put';

  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { user: 'user' } });
}

export function deleteUser (id) {
  const url = ApiUrl(`/users/${id}`);

  return Call('delete', url, {}, ParseGeneralResponse);
}

export function fetchAdHocUsers () {
  const url = ApiUrl('/users/list_adhoc_users');

  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { users: 'users' } });
}

export function updateUserProfileFields (data) {
  const url = ApiUrl('/users/update_profile_fields');

  return Call( 'post', url, data, ParseGeneralResponse);
}

export function getGlobalNewBuyerRequirements () {
  const url = ApiUrl('/global/new_buyer_requirements_checklist');

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { checklist: 'checklist' } },
  );
}

export function refreshToken () {
  const url = ApiUrl('/users/refresh_token');

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { current_user: 'current_user' } }
  );
}

export function refreshPageIfNotSignedIn () {
  refreshToken()
    .then(result => {
      if (!result.success) return;
      if (typeof window === 'undefined') return;

      window.location.reload();
    });
}

export function sendMassSelfServeInvite (emailList) {
  const url = ApiUrl('/users/mass_self_serve_invite');

  return Call(
    'post',
    url,
    { self_serve_emails: emailList },
    ParseGeneralResponse,
    { responseDataKeys: { sentEmails: 'sentEmails', errors: 'errors' } }
  );
}

export function unlockUser (id) {
  const url = ApiUrl('/users/unlock_user');

  return Call(
    'post',
    url,
    { id },
    ParseGeneralResponse,
    { responseDataKeys: { success: 'success', errors: 'errors' } }
  );
}

export function lockUser (id) {
  const url = ApiUrl('/users/lock_user');

  return Call(
    'post',
    url,
    { id },
    ParseGeneralResponse,
    { responseDataKeys: { success: 'success', errors: 'errors' } }
  );
}

export function assignUserRole (roleId, userId) {
  const url = ApiUrl(`/users/${userId}/attach_role`);

  return Call('patch', url, { role_id: roleId }, ParseGeneralResponse);
}

export function removeUserRole (roleId, userId) {
  const url = ApiUrl(`/users/${userId}/detach_role`);

  return Call('delete', url, { role_id: roleId }, ParseGeneralResponse);
}
