import PropTypes from 'prop-types';
import { Component, createContext } from 'react';
import { Global } from '@utils/common';

// Benefit of a monorepo would allow us to not have to fetch this configuration at page load.
// How could be get a globals.json put in at build-time that pulls from the rails app to ensure it's
// always available?
const DEFAULT_CONFIG = {
  global: Global
};

// This Provider should never contain a reference to User and is purely for config options.
export const ConfigurationsContext = createContext({
  setConfigurationsProperty: () => {},
  global: Global
});

class ConfigurationsProvider extends Component {
  constructor (props) {
    super(props);

    const config = DEFAULT_CONFIG;

    // `global` state will also be available from the User Provider
    this.state = { ...config };
  }

  componentDidMount () {
    this.isSubscribed = true;
  }

  componentWillUnmount () {
    this.isSubscribed = false;
  }

  render () {
    return (
      <ConfigurationsContext.Provider
        value={{
          ...this.state,
          setConfigurationsProperty: this.setConfigurationsProperty
        }}
      >
        {this.props.children}
      </ConfigurationsContext.Provider>
    );
  }
}

ConfigurationsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ConfigurationsProvider;
