import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import axios from 'axios';
import queryString from 'query-string';
import AppConfig from 'config/AppConfig';
import { RAILS_SORT_ORDER_MAP } from 'utils/sorting';

export function PageParams (page, perPage) {
  return `page=${page || 1}&per_page=${perPage || AppConfig.perPage}`;
}

export function ApiUrl (path) {
  return `/api/v1${path}`;
}

export function ExternalApiUrl (path) {
  return path;
}

// Convert a hash of filters to an array
export const filtersToArray = filters => {
  const arr = [];

  Object.keys(filters).forEach(key => {
    const obj = { field: key, values: [] };

    filters[key].forEach(value => {
      obj.values.push(value);
    });

    arr.push(obj);
  });

  return arr;
};

export const filterSafeQueryString = params => {
  const safeParams = { ...params };
  const arrayFilters = filtersToArray(params.filters);
  const safeFilters = queryString.stringify({ filters: JSON.stringify(arrayFilters) }, { arrayFormat: 'index' });

  delete safeParams.filters;

  return `${queryString.stringify(safeParams)}&${safeFilters}`;
};

export function getUrlWithPagination (
  basePath,
  required = '',
  current = 1,
  pageSize = 20,
  sortField = 'name',
  sortOrder = 'asc',
  filters = [],
  search = ''
) {
  const requiredArgs = `${required}`;
  const pageArgs = `&page_num=${current}&per_page=${pageSize}`;
  const sortArgs = `&sort=${sortField}&sort_order=${RAILS_SORT_ORDER_MAP[sortOrder]}`;
  const safeFilters = queryString.stringify({ filters: JSON.stringify(filters) }, { arrayFormat: 'index' });
  const filterArgs = `&${safeFilters}`;
  const searchArgs = `&search=${search}`;

  return ApiUrl(`${basePath}?${requiredArgs}${pageArgs}${sortArgs}${filterArgs}${searchArgs}`);
}

export function NodeServiceUrl (path) {
  return `${AppConfig.nodeUrl}/${path || ''}`;
}

export function HttpErrorMessage (error) {
  if (_get(error, 'response.data.errors', null)) {
    return error.response.data.errors;
  }
  return [error.message];
}

export function ParseGeneralResponse (success, response, options, status) {
  const result = {
    success,
    status,
    request: response.request,
    headers: response.headers
  };

  if (!_isEmpty(options.return)) {
    result.return = options.return;
  }

  if (success) {
    const dataKeys = options.responseDataKeys || {};

    _forEach(dataKeys, (value, key) => {
      const valueKey = _isEmpty(value) ? 'data' : `data.${value}`;

      result[key] = _get(response, valueKey, null);
    });
  } else {
    result.errors = HttpErrorMessage(response);
  }

  return result;
}

export function ParseDataResponse (success, response, options, status) {
  const result = {
    success,
    status,
    request: response.request,
    headers: response.headers
  };

  if (success) {
    result.data = response.data;
  } else {
    result.errors = HttpErrorMessage(response);
  }

  return result;
}

export function parseFileResponse (success, response) {
  const result = { success, status: response.status };

  if (success) {
    result.data = response.data;
    result.headers = response.headers;
  } else {
    result.errors = HttpErrorMessage(response);
  }

  return result;
}

// + is not a valid character in a url and gets converted to a space
// this is bad when we need to pass emails like sam+buyer@bidops.com
export const safeQueryString = string => string.replace(/\+/g, '%2B');

export function Call (
  method,
  url,
  data,
  parseCallback,
  options = {},
  withCredentials = true,
  responseType = 'json',
  cancelTokenSource = {},
  headers = {}
) {
  return new Promise(resolve => {
    axios({
      method,
      url: safeQueryString(url),
      data,
      withCredentials,
      responseType,
      cancelToken: cancelTokenSource.token,
      headers
    })
      .then(response => {
        resolve(parseCallback(true, response, options, response.status));
      })
      .catch(error => {
        // Error can happen on the request before a response was received therefore no "response" key would exist
        const statusCode = error.response && error.response.status;

        resolve(parseCallback(false, error, options, statusCode));
      });
  });
}
