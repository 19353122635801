import useTranslation from 'next-translate/useTranslation';

export const SpendTypesTooltipContent = (): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <span data-testid='spend-types-tooltip-content'>
      <div>
        <strong>{t('tooltip.onboarding.direct_spend')}:</strong>
        <br />
        {t('tooltip.onboarding.buyer.direct_spend')}
      </div>
      <div>
        <strong>{t('tooltip.onboarding.indirect_spend')}:</strong>
        <br />
        {t('tooltip.onboarding.buyer.indirect_spend')}
      </div>
      <div>
        <strong>{t('tooltip.onboarding.other')}:</strong>
        <br />
        {t('tooltip.onboarding.buyer.other')}
      </div>
    </span>
  );
};
