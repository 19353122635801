import { Dispatch, SetStateAction, useContext } from 'react';
import { Button } from '@arkestro/arkestro-design-system';
import { CheckCircleOutlined } from '@ant-design/icons';
import useTranslation from 'next-translate/useTranslation';

import { UserContext } from '@components/providers/UserProvider/UserProvider';
import { idRequestMapAndNotificationMap } from '@components/layout/Header/Notifications/utils';
import { NotificationsContext } from '@components/providers/NotificationsProvider/NotificationsProvider';
import { GeneralContext } from '@components/providers/GeneralProvider/GeneralProvider';
import NotificationsListGroup
  from '@components/layout/Header/Notifications/NotificationsListGroup/NotificationsListGroup';
import { Notification } from '@components/layout/Header/Notifications/types';
import NotificationsGeneralListGroup
  from '@components/layout/Header/Notifications/NotificationsGeneralListGroup/NotificationsGeneralListGroup';
import { markAllRead } from '@utils/StreamNotificationUtils';
import { numericalSort } from '@utils/sorting';
import { combineClassNames } from '@utils/classNames';

import style from './NotificationsDropdown.module.scss';

type Props = {
  list: Notification[];
  setList: Dispatch<SetStateAction<Notification[]>>;
};

const NotificationsDropdown = ({ list = [], setList }: Props): JSX.Element => {
  const { t } = useTranslation('common');
  const user = useContext(UserContext);
  const { streamNotificationsFeed: feed } = useContext(NotificationsContext);
  const { companyInvites = [], bidAcceptances = [] } =
    useContext(GeneralContext);
  const fullListCount =
    list.length + companyInvites.length + bidAcceptances.length;
  const [requestTitleMapById, notificationsMapByRequest] =
    idRequestMapAndNotificationMap(list);

  const handleDismissAll = () => {
    markAllRead(feed, user);
    setList([]);
    // setShowPopover(false); // TODO: how do we close a v5 popover?
  };

  if (!list || fullListCount === 0) {
    return (
      <div
        className={style.container}
        data-testid='notifications-dropdown-popover-empty'
      >
        <div className={style.emptyList}>
          <CheckCircleOutlined className={style.icon} />
          <div className={style.title}>{t('notifications.no_new')}</div>
        </div>
      </div>
    );
  }

  // Order the notification groups by the newest request at the top (e.g., the highest request ID)
  const sortedRequestIds = Object.keys(requestTitleMapById).sort((a, b) => numericalSort(a, b, null, 'desc'));

  return (
    <div
      className={combineClassNames(style.container, style.standardScrollbar)}
      data-testid='notifications-dropdown-popover'
    >
      <div className={style.header}>
        <h1 className={style.title} data-testid='notifications-dropdown-title'>
          {t('notifications.notifications')}
        </h1>
        {user.is_buyer && (
          <Button
            type='link'
            onClick={handleDismissAll}
            data-testid='notifications-dropdown-dismiss-all'
          >
            {t('notifications.actions.dismiss_all')}
          </Button>
        )}
      </div>
      <div className={style.groupWrapper}>
        <NotificationsGeneralListGroup setAllList={setList} allList={list} />
        {list.length > 0 &&
          sortedRequestIds.map(requestId => (
            <NotificationsListGroup
              key={`request-${requestId}`}
              title={requestTitleMapById[requestId]}
              list={notificationsMapByRequest[requestId]}
              setAllList={setList}
              allList={list}
            />
          ))}
      </div>
    </div>
  );
};

export default NotificationsDropdown;
