import PropTypes from 'prop-types';
import { useState } from 'react';
import { Col, Row } from '@arkestro/arkestro-design-system';
import ScreenIndicators from './ScreenIndicators';
import style from './Carousel.module.scss';

const Carousel = ({ steps, onChange }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const stepCount = steps.length;
  const step = steps[currentStep];

  const setStep = stepNum => {
    setCurrentStep(stepNum);

    if (onChange) onChange(stepNum, stepCount);
  };

  return (
    <div className={style.wrapper}>
      <Row>
        <Col sm={12}>
          <div className={style.textAndNavWrapper}>
            <div className={style.textWrapper}>
              <div className={style.title}>{step.title}</div>
              <div className={style.subtitle}>{step.text}</div>
            </div>
            <div className={style.ScreenIndicators}>
              <ScreenIndicators stepCount={stepCount} activeStep={currentStep} setCurrentStep={setStep} />
            </div>
          </div>
        </Col>
        <Col sm={12}>
          <div className={style.imageWrapper}>
            {step.image && <img src={step.image} alt={step.title} />}
          </div>
        </Col>
      </Row>
    </div>
  );
};

Carousel.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func
};

Carousel.defaultProps = {
  onChange: () => {}
};

export default Carousel;
