import axios from 'axios';
import { StreamChat } from 'stream-chat';
import AppConfig from 'config/AppConfig';

// Sign In / Out

export const signIn = (params: any) => (
  axios.post('/api/v1/auth', params)
    .then(response => response)
    .catch(error => error)
);

export const deeplinkLogin = (params: any) => (
  axios.post('/api/v1/auth/deeplink_login', params)
    .then(response => response)
    .catch(error => error)
);

export const signOut = () => (
  axios.delete('/api/v1/auth/sign_out')
    .then(response => {
      try {
        const chatClient = StreamChat.getInstance(AppConfig.streamKey);

        chatClient.disconnectUser();
      } catch {}

      return response;
    })
    .catch(error => error)
);

// Refresh Token

export const refreshAccessToken = () => (
  axios.post('/api/v1/auth/refresh')
    .then(response => response)
    .catch(error => error)
);

// Current User

export const getMe = () => (
  axios.post('/api/v1/auth/me')
    .then(response => response)
    .catch(error => error)
);

export const getMyEmail = (token: string) => (
  axios.post('/api/v1/auth/my_email', { invite_token: token })
    .then(response => response)
    .catch(error => error)
);

// Health Check
export const getHealth = () => (
  axios.get('/api/v1/auth/health')
    .then(response => response)
    .catch(error => error)
);

// SSO

// Note: This routes through our Omniauth callbacks controller
export const ssoSignIn = (email: string) => (
  axios.get(`/api/v1/auth/sso?email=${email}&is_preflight=true`)
    .then(response => response)
    .catch(error => error)
);

// Forgot Password / Reset Password / Create Account

export const sendResetPasswordEmail = (params: any) => (
  axios.post('/api/v1/auth/send_reset_password_email', params)
    .then(response => response)
    .catch(error => error)
);

export const resetPassword = (params: any) => (
  axios.post('/api/v1/auth/reset_password', params)
    .then(response => response)
    .catch(error => error)
);

export const createAccount = (params: any) => (
  axios.post('/api/v1/auth/create_account', params)
    .then(response => response)
    .catch(error => error)
);

// Impersonation

export const impersonate = (params: any) => (
  axios.post('/api/v1/auth/impersonate', params)
    .then(response => response)
    .catch(error => error)
);

export const stopImpersonating = () => (
  axios.post('/api/v1/auth/stop_impersonating')
    .then(response => response)
    .catch(error => error)
);
