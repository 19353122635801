import {
  ClockCircleFilled,
  ContactsFilled, CrownFilled,
  DollarCircleFilled,
  FallOutlined,
  FileTextFilled,
  MailFilled,
  WarningFilled
} from '@ant-design/icons';

type Props = {
  iconType: string;
};

const NotificationsListItemIcon = ({ iconType }: Props):JSX.Element => {
  const defaultColorStyle = { color: 'rgba(0, 0, 0, 0.45)' };
  const testId = 'list-item-icon';

  // mapping is based on StreamNotificationRequest and StreamNotificationUser children usage
  // and used for non-default icons

  // TODO: create Ready to Award notification - ticket #186478987
  // 'ViewAwardTab' => (<CrownFilled style={{ color: 'rgba(250, 140, 22, 1)' }} />)

  // sometimes the type comes with additional info as a suffix, we don't need it to determine the type.
  const typeParts = iconType?.split('-') || [];
  const notificationIconType = typeParts[0];

  if ([
    'ViewSuggestedPrices'
  ].includes(notificationIconType)) return (<DollarCircleFilled style={defaultColorStyle} data-testid={testId} />);
  if ([
    'ViewRequestVendorsTab'
  ].includes(notificationIconType)) return (<FallOutlined style={defaultColorStyle} data-testid={testId} />);
  if ([
    'ViewRequestFormatReduceTime',
    'ViewRequestFormatAddTime'
  ].includes(notificationIconType)) return (<ClockCircleFilled style={defaultColorStyle} data-testid={testId} />);
  if ([
    'viewRequestDocumentsTab', 'ViewRequestDocuments'
  ].includes(notificationIconType)) return (<FileTextFilled style={defaultColorStyle} data-testid={testId} />);
  if ([
    'company_invitation'
  ].includes(notificationIconType)) return (<ContactsFilled style={defaultColorStyle} data-testid={testId} />);
  if ([
    'request_invitation'
  ].includes(notificationIconType)) return (<MailFilled style={defaultColorStyle} data-testid={testId} />);
  if ([
    'ViewAwardTab',
  ].includes(notificationIconType)) return (<CrownFilled style={defaultColorStyle} data-testid={testId}/>);

  // Default Icon
  // 'ViewVendorSurvey' => HighEvaluationCriteriaImpact
  // 'viewRequestVendorsTab' => UpdatedSupplierRecommendations
  // 'viewSupplierSurveyAsSupplier' => CompleteSupplierSurvey, LineItemsUpdated, UpdateSupplierSurvey
  // 'ViewRequestDashboardVendorSubmission' => VendorSubmissionReceived
  // 'VendorInviteBounced' => VendorInviteBounced
  // 'TeamInviteBounced' => TeamInviteBounced
  return (
    <WarningFilled style={defaultColorStyle} data-testid={testId} />
  );
};

export default NotificationsListItemIcon;
