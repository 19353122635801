import PropTypes from 'prop-types';
import colors from 'utils/colors';
import LogoImage from 'components/LogoImage/LogoImage';

const LOGO_STYLE = { display: 'table', width: '100%', height: 90, paddingLeft: 24 };
const IMAGE_WRAPPER_STYLE = { position: 'relative', display: 'table-cell', verticalAlign: 'middle' };
const IMG_STYLE = { maxWidth: 150 };
const LOGO_MASK_STYLE = {
  position: 'absolute',
  background: colors.grey.dark,
  top: 0,
  right: 0,
  bottom: 0,
  left: 38 // the offset needed to cover logo text
};

const Logo = ({ collapsed }) => (
  <div style={LOGO_STYLE}>
    <div style={IMAGE_WRAPPER_STYLE}>
      {collapsed && <div style={LOGO_MASK_STYLE} />}
      <LogoImage imageStyle={IMG_STYLE} />
    </div>
  </div>
);

Logo.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default Logo;
