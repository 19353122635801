export const newPasswordRules = (t, global, newPassword) => {
  const minPasswordLength = global.passwords.min_length;
  const maxPasswordLength = global.passwords.max_length;

  let errorMessage = '';

  if (!newPassword || typeof(newPassword) !== 'string') {
    errorMessage = t('login.wrong_password_format');
  }
  else if (newPassword.length < minPasswordLength) {
    errorMessage = t('login.password_too_short', { length: minPasswordLength });
  }
  else if (newPassword.length > maxPasswordLength) {
    errorMessage = t('login.password_too_long', { length: maxPasswordLength });
  }

  if (errorMessage) return [errorMessage];

  return [];
};

export const parseErrorsIntoString = errors => {
  let returnStringList = [];

  if (typeof errors === 'object') {
    Object.keys(errors).forEach( key => {
      const error = errors[key];

      if (Array.isArray(error)) {
        returnStringList = [...returnStringList, ...error];
      }
      else if (typeof error === 'string') {
        returnStringList.push(error);
      }
    });
  }
  else if (Array.isArray(errors)) {
    returnStringList = [...returnStringList, ...errors];
  }
  else if (typeof errors === 'string') {
    returnStringList.push(errors);
  }

  return [...new Set(returnStringList)].reduce((acc, string) => (`${acc} ${string}`), '').trim();
};
