import PropTypes from 'prop-types';
import { Component, createContext } from 'react';
import { withRouter } from 'next/router';
import { UserContext } from '@providers/UserProvider/UserProvider';
import { alertMessage } from 'utils/common';
import { setOnBoardStep, VENDOR_STEPS } from 'api/UserApi';

const DEFAULT_OPTIONS = {
  progress: false,
  currentOrgStep: 'confirm_affiliation',
  currentWelcomeStep: 'welcome',
  vendorOrg: undefined // { id, name } at minimum or entire supplier org record
};

// currentOrgStep options:
// confirm_affiliation, no_org_match, create_org, completed

// currentWelcomeStep options:
// welcome, carousel

export const VendorOnboardContext = createContext({
  ...DEFAULT_OPTIONS,
  setProperty: () => {}
});

class VendorOnboardProvider extends Component {
  constructor (props) {
    super(props);

    this.user = props.user;
    this.state = {
      ...DEFAULT_OPTIONS,
      currentStepName: this.user.on_boarding || 'intro'
    };

    this.setProperty = this.setProperty.bind(this);
    this.updateOnboardStep = this.updateOnboardStep.bind(this);
  }

  setProperty (property) {
    this.setState(property);
  }

  updateOnboardStep (currentStep) {
    this.setState({ progress: true });
    setOnBoardStep(VENDOR_STEPS[currentStep])
      .then(({ success, errors, step }) => {
        if (!success) {
          alertMessage(errors[0], 'error', 10);
          this.setState({ progress: false });
          return;
        }
        if (step === 'completed') {
          this.props.router.reload();
          return;
        }
        this.setState({ progress: false, currentStepName: step });
      });
  }

  render () {
    return (
      <VendorOnboardContext.Provider
        value={{
          ...this.state,
          setProperty: this.setProperty,
          updateOnboardStep: this.updateOnboardStep
        }}
      >
        {this.props.children}
      </VendorOnboardContext.Provider>
    );
  }
}

VendorOnboardProvider.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.shape().isRequired,
};

const exportedComponent = props => <UserContext.Consumer>
  {user => <VendorOnboardProvider {...props} user={user} />}
</UserContext.Consumer>;

export default withRouter(exportedComponent);
