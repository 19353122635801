import axios from 'axios';
import gql from 'graphql-tag';
import { createClient as genQLCreateClient } from '../graphql';

const SERVER_GRAPHQL_PATH = '/api/v1/graphql';

export const defaultPageInfo = max => ({
  pageSize: max,
  startCursor: undefined,
  endCursor: undefined,
  hasNextPage: true,
  hasPreviousPage: false,
  __typename: undefined
});

// Use with paginated graphql queries
export const BASE_CONNECTION_INFO = {
  totalCount: true,
  pageInfo: {
    endCursor: true,
    startCursor: true,
    hasPreviousPage: true,
    hasNextPage: true,
  }
};

export const ADD_OR_UPDATE_REQUEST_CHANNELS = gql`
  mutation AddOrUpdateRequestChannels($requestId: Int!, $subdomain: String!, $companyName: String!, $buyers: [JSONObject]!,
      $vendorGroups: [JSONObject]!, $vendors: [JSONObject]!, $requestName: String! ) {
        addOrUpdateRequestChannels(requestId: $requestId, subdomain: $subdomain, companyName: $companyName,
          vendorGroups: $vendorGroups, vendors: $vendors, buyers: $buyers, requestName: $requestName)
  }
`;

export const CREATE_AD_HOC_CHANNEL = gql`
  mutation CreateAdHocChannel($vendors: [JSONObject]!, $buyers: [JSONObject]!, $subdomain: String!, $companyName: String!) {
    createAdHocChannel(vendors: $vendors, buyers: $buyers, subdomain: $subdomain, companyName: $companyName)
  }
`;

export const GET_UNIFIED_BID_TABLE = gql`
    query unifiedBidTable($tableOptions: JSONObject) {
      unifiedBidTable(tableOptions: $tableOptions)
    }
`;

// Wrapper on createClient from @genql/cli that includes credentials to make sure we're
// always passing cookies for auth.
export const createClient = (options = {}) => (
  genQLCreateClient({
    credentials: 'include',
    ...options,
    fetcher: operation => (
      axios.post(SERVER_GRAPHQL_PATH, operation)
        .then(response => response.data)
        .catch(error => error)
    )
  })
);

export const extractErrorMessageFromGenqlError = (error, fallback) => {
  const message = error.message?.split('\n')[0];

  return message || fallback;
};
