// history.action replacement. Indicates if page was fresh load or came from another page
export const shouldResolveHref = () => window.history.state.options._shouldResolveHref;

export const truncateText = (text, charCountLimit = 25) => {
  if (text.length > charCountLimit) {
    return `${text.substring(0, charCountLimit)}...`;
  }

  return text;
};
