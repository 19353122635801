import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export const getBidAcceptance = id => {
  const url = ApiUrl(`/bid_acceptances/${id}`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { bid_acceptance: 'bid_acceptance' } }
  );
};

export const getBidAcceptances = (answer = [], ids = []) => {
  const url = ApiUrl(`/bid_acceptances?answer=${answer}&ids=${ids}`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { bid_acceptances: 'bid_acceptances' } }
  );
};

export function updateBidAcceptance (id, answer) {
  const url = ApiUrl(`/bid_acceptances/${id}`);

  return Call(
    'put',
    url,
    {
      answer
    },
    ParseGeneralResponse,
    { responseDataKeys: { bid_acceptance: 'bid_acceptance' } }
  );
}
