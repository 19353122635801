import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export function getSpendCategories () {
  const url = ApiUrl('/spend_category_list');

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          spendCategories: 'spend_categories'
        },
    },
  );
}

export function getSpendCategoriesForCurrentUser () {
  const url = ApiUrl('/user_spend_category_list');

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          spendCategories: 'spend_categories'
        },
    },

  );
}

export function updateSpendCategoriesForCurrentUser (list) {
  const url = ApiUrl('/update_user_spend_categories');
  const method = 'post';

  return Call(
    method,
    url,
    { user_spend_category_list: list },
    ParseGeneralResponse,
  );
}
