import { message } from 'antd-v3';
import Currencies from 'currency-codes';
import FormErrors from '../components/shared/FormErrors';
import { getParamId, getParam } from './Utils';
import Global from '../global.json';

export const ERROR_MESSAGE_DURATION = 10;

function alertMessage (messageToShow, type = 'success', duration = 2) {
  switch (type) {
    case 'warning': {
      message.warning(messageToShow, duration);
      return;
    }
    case 'error': {
      message.error(messageToShow, duration);
      return;
    }
    default: {
      message.success(messageToShow, duration);
    }
  }
}

export {
  alertMessage,
  Global,
  Currencies,
  FormErrors,
  getParam,
  getParamId
};
