import { datadogRum } from '@datadog/browser-rum';

class DatadogService {
  static initialize = () => {
    if (!process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN) return;
    if (!process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID) return;

    DatadogService.connect();
  }

  static connect = () => {

    datadogRum.init({
      clientToken: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN,
      applicationId: process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID,
      version: process.env.NEXT_PUBLIC_DD_VERSION,
      site: 'datadoghq.com',
      service: 'arkestro-client',
      env: process.env.NEXT_PUBLIC_ARKESTRO_ENV,
      sessionSampleRate: 100, // sampleRate (v4) sessionSampleRate (v5)
      sessionReplaySampleRate: 100,
      trackUserInteractions: true, // trackInteractions (v4) trackUserInteractions (v5)
      defaultPrivacyLevel: 'allow',
      trackResources: true,
      trackLongTasks: true,
      silentMultipleInit: true,
      allowedTracingUrls: [/^https:\/\/.*\.arkestro(-[a-zA-Z]*)?\.com$/], // allowedTracingOrigins (v4) allowedTracingUrls (v5)
      enableExperimentalFeatures: ['feature_flags']
    });
  }

  static configureUser = user => {
    if (!user) return;

    datadogRum.setUser({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      is_superadmin_session: `${user.impersonating}`,
      superadmin_user: user.true_user
    });

    datadogRum.startSessionReplayRecording();
  }
}

export default DatadogService;
