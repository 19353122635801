import _uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { Component, createContext } from 'react';
import { attachTrackingAnalytics } from '@services/SegmentService';
import { SENT_MESSAGE, CONVERSATION_ID } from 'utils/analytics_constants';
import { UserContext } from 'components/providers/UserProvider/UserProvider';
import { setupStream } from 'services/StreamService';
import { getChannelIdsWithUnread, channelIdWithoutType } from 'utils/chat';

const DEFAULT_OPTIONS = {
  unreadChannelIds: [],
  streamChatToken: undefined,
  streamClient: undefined,
  unreadCount: undefined,
  showSettingsMenu: false, // settings menu in chat channel
  activeChannel: undefined,
  newActiveChannel: undefined,
  showNewChannelModal: false
};

export const ConversationsContext = createContext({
  ...DEFAULT_OPTIONS,
  setProperty: () => {}
});

class ConversationsProvider extends Component {
  constructor (props) {
    super(props);

    this.state = {
      ...DEFAULT_OPTIONS,
      gqlClient: props.gqlClient
    };

    this.setProperty = this.setProperty.bind(this);
  }

  async componentDidMount () {
    // We want give stream a second to do it's thing to avoid multiple rerenders of child components that
    // use streamClient object. This only happens on initial page load/mount.
    const streamClient = await setupStream(this, this.props.gqlClient, this.props.user);
    const provider = this;

    // Don't fetch unread channels for synthetics to avoid errors.
    // TODO: Remove this when unread logic is refactored for performance.
    // https://www.pivotaltracker.com/story/show/185596425
    if (this.props.user.subdomain === 'synthetics') return;

    // Get all unread channels and set to state initially then use streamClient listener
    getChannelIdsWithUnread(streamClient, this.props.user)
      .then(({ unreadChannelMapFromFetch, unreadChannelIdsFromFetch }) => {
        provider.setState({
          unreadChannelIds: unreadChannelIdsFromFetch,
          unreadCount: unreadChannelIdsFromFetch.length,
          unreadChannelMap: unreadChannelMapFromFetch
        }, () => {
          // React batches setState during multiple event calls, so we need to update the unreadCount outside
          // of the event block because some events return undefined for total_unread_count.
          let asyncUnreadCount = unreadChannelIdsFromFetch.length;

          // Anytime a Stream event is fired, update unread channels but don't fetch new data. Just listen.
          // Pay attention to CID names that are prepended with "messaging:" but channel object IDs do not
          // prepend and instead have a "type" property.
          streamClient.on(event => {
            const { type, user: eventUser, cid } = event;
            const isNewMessage = (type === 'message.new' || type === 'notification.message_new');
            const isMe = eventUser && eventUser.id === this.props.user.id.toString();
            const isRead = (type === 'notification.mark_read' || type === 'message.read');

            if (!isNewMessage && !isRead) return; // don't care about health checks

            // I wrote the message so don't update anything
            if (isNewMessage && isMe) {
              if (cid) attachTrackingAnalytics(SENT_MESSAGE, { [CONVERSATION_ID]: cid });
              return;
            }

            if (isRead && !isMe) return; // don't care about others reading their own messages

            const { total_unread_count: totalUnread } = event;

            if (totalUnread !== undefined && totalUnread >= 0) asyncUnreadCount = totalUnread;

            // Handle batching issue with setState
            // https://reactjs.org/docs/react-component.html#setstate
            provider.setState(state => {
              const { unreadChannelIds, unreadChannelMap } = state;
              // Define these after guard clauses to avoid undefined channel id for health checks
              const eventCID = event.cid || (event.channel && event.channel.id.toString());
              const unreadMessagesCount = (event.channel && event.channel.countUnread)
                ? event.channel.countUnread()
                : event.unread_count;

              const fullEventCID = channelIdWithoutType(eventCID);
              const indexInExistingChannels = unreadChannelIds
                .findIndex(channelID => fullEventCID === channelIdWithoutType(channelID));

              // Make sure we aren't setting unreadChannelIds array if total unread is 0;
              const updatedUnreadChannelIds = asyncUnreadCount > 0 ? _uniq(unreadChannelIds) : [];

              // Unreliable map at the moment due to unread count being conditionally for everything for the user
              const updatedUnreadChannelMap = { ...unreadChannelMap };

              updatedUnreadChannelMap[fullEventCID] = unreadMessagesCount;

              // If it exists in unreadChannelIds and is new message, leave it as is
              if (indexInExistingChannels > -1 && isMe) {
                // Channel is no longer unread, so remove it
                updatedUnreadChannelIds.splice(indexInExistingChannels, 1);
              } else if (isNewMessage && asyncUnreadCount > 0) {
                // Channel not in unreadChannelIds, add channel to unreadChannelIds
                updatedUnreadChannelIds.push(fullEventCID);
              }
              const uniqueUpdatedUnreadChannelIds = _uniq(updatedUnreadChannelIds);

              return {
                unreadChannelIds: uniqueUpdatedUnreadChannelIds,
                unreadCount: updatedUnreadChannelIds.length,
                unreadChannelMap: updatedUnreadChannelMap
              };
            });
          });
        });
      });
  }

  setProperty (property) {
    this.setState(property);
  }

  render () {
    return (
      <ConversationsContext.Provider
        value={{
          ...this.state,
          setProperty: this.setProperty
        }}
      >
        {this.props.children}
      </ConversationsContext.Provider>
    );
  }
}

ConversationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  gqlClient: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired
};

const exportedComponent = props => (
  <UserContext.Consumer>
    {user => <ConversationsProvider {...props} user={user} />}
  </UserContext.Consumer>
);

export default exportedComponent;
