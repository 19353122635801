import { LeftOutlined } from '@ant-design/icons';
import { Button, type ButtonProps } from '@arkestro/arkestro-design-system';
import { safeKeyPress } from 'utils/accessibility';

interface BackButtonProps extends ButtonProps {
  hideIcon?: boolean;
}

export const BackButton = ({
  onClick,
  className,
  children,
  hideIcon,
  style,
}: BackButtonProps) => (
  <Button
    className={className}
    onClick={onClick}
    onKeyPress={e => safeKeyPress(e, onClick)}
    tabIndex={0}
    style={style}
  >
    {!hideIcon && <LeftOutlined />}
    {children || 'Back'}
  </Button>
);
