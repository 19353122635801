import PropTypes from 'prop-types';
import { Component, createContext } from 'react';
import { setupStreamNotifications } from 'services/StreamService';
import { UserContext } from 'components/providers/UserProvider/UserProvider';

/**
 * For setting up custom contexts to user Stream Notifications
 * @type {React.Context<{setProperty: NotificationsContext.setProperty}>}
 */
export const NotificationsContext = createContext({
  streamNotificationsFeed: null,
  unseenCount: 0,
  setProperty: () => {},
});

/**
 * Provider for notifications related behavior, current purpose is to act as
 * a provider for custom stream notifications web sockets
 */
class NotificationsProvider extends Component {
  constructor (props) {
    super(props);

    this.state = {
      streamNotificationsFeed: null,
      unseenCount: props.unseenCount || 0,
    };

    this.setProperty = this.setProperty.bind(this);
  }

  async componentDidMount () {
    await setupStreamNotifications(this, this.state.unseenCount, this.setProperty, this.props.user);
  }

  setProperty (property) {
    this.setState(property);
  }

  render () {
    return (
      <NotificationsContext.Provider
        value={{
          ...this.state,
          setProperty: this.setProperty
        }}
      >
        {this.props.children}
      </NotificationsContext.Provider>
    );
  }
}

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const exportedComponent = props => (
  <UserContext.Consumer>
    {user => <NotificationsProvider {...props} user={user} />}
  </UserContext.Consumer>
);

export default exportedComponent;
