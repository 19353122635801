import { Translate } from 'next-translate';

export const prettyBusinessRegionsMap = (t: Translate) => ({
  na: t('business_regions.na'),
  emea: t('business_regions.emea'),
  apac: t('business_regions.apac'),
  latam: t('business_regions.latam'),
  undefined: ''
});

export const prettyBusinessRegion = (t: Translate, region: 'na' | 'emea' | 'apac' | 'latam' | undefined) => (
  prettyBusinessRegionsMap(t)[`${region}`]
);
