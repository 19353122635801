import useTranslation from 'next-translate/useTranslation';
import { Tooltip } from '@arkestro/arkestro-design-system';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { PopConfirm } from './PopConfirm/PopConfirm';

const LOCKED_COLOR = '#be0000';
const UNLOCKED_COLOR = '#000000';

interface LockProps {
  content: string;
  locked: boolean;
  confirm?: boolean;
  onConfirm?: () => void;
};

export const Lock = ({
  content,
  locked,
  confirm = false,
  onConfirm = () => {}
}: LockProps) => {
  const { t } = useTranslation('common');

  const determineIcon = () => {
    return locked ? (
      <LockOutlined style={{ color: LOCKED_COLOR }} />
    ) : (
      <UnlockOutlined style={{ color: UNLOCKED_COLOR }} />
    );
  };

  return (
    confirm ? (
      <PopConfirm
        placement='topRight'
        title={content}
        onConfirm={onConfirm}
        cancelText={t('general.no')}
      >
        {determineIcon()}
      </PopConfirm>
    ) : (
      <Tooltip title={content}>
        {determineIcon()}
      </Tooltip>
    )
  );
};
