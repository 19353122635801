import { useState } from 'react';
import FloatingPanel from 'components/on_board/FloatingPanel/FloatingPanel';
import { VendorOnboardContext } from 'components/on_board/vendor/VendorOnboardProvider/VendorOnboardProvider';
import AffiliationConfirmation from './AffiliationConfirmation/AffiliationConfirmation';
import CreateNewOrgFields from './CreateNewOrgFields/CreateNewOrgFields';
import FindOrCreateOrganization from './FindOrCreateOrganization/FindOrCreateOrganization';

const COL_SIZE_HASH_MAP = {
  confirm_affiliation: {},
  no_org_match: { xl: 10 },
  create_org: { sm: 20, md: 18, lg: 14, xl: 12 },
};

const CompanyAssignmentWizard = () => {
  const [isFreeEmail, setIsFreeEmail] = useState(true); // always assume it's free email

  return (
    <VendorOnboardContext.Consumer>
      {({ currentStepName, currentOrgStep, updateOnboardStep, updateOrgId, setProperty, vendorOrg, progress }) => (
        <FloatingPanel colSizes={COL_SIZE_HASH_MAP[currentOrgStep]}>
          <div className='' id='vendor-organization-wizard'>
            {currentOrgStep === 'confirm_affiliation' && (
              <AffiliationConfirmation
                updateOnboardStep={updateOnboardStep}
                setProperty={setProperty}
                vendorOrg={vendorOrg}
                currentStepName={currentStepName}
                setIsFreeEmail={setIsFreeEmail}
                isFreeEmail={isFreeEmail}
              />
            )}
            {currentOrgStep === 'no_org_match' && (
              <FindOrCreateOrganization
                updateOnboardStep={updateOnboardStep}
                setOrgStep={setProperty}
              />
            )}
            {currentOrgStep === 'create_org' && (
              <CreateNewOrgFields
                updateOnboardStep={updateOnboardStep}
                setOrgStep={setProperty}
                currentStepName={currentStepName}
                updateOrgId={updateOrgId}
                isFreeEmail={isFreeEmail}
                progress={progress}
              />
            )}
          </div>
        </FloatingPanel>
      )}
    </VendorOnboardContext.Consumer>
  );
};

export default CompanyAssignmentWizard;
