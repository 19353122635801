import { AWARD_GRAPH_ICON, CHAT_USERS_ICON, SEARCH_PROFILES_MAGNIFIER } from '@utils/images';

const step1SVG = AWARD_GRAPH_ICON;
const step2SVG = CHAT_USERS_ICON;
const step3SVG = SEARCH_PROFILES_MAGNIFIER;

const steps = [
  {
    title: 'Grow your business quickly.',
    text: `Our automated ranking and price suggestion tools help you bid confidently\n
     and competitively—so you can get to the purchase order phase faster.`,
    image: step1SVG
  },
  {
    title: 'Engage customers along the way.',
    text: `Use in-app messages to ask questions and respond to buyers throughout the \n
    request lifecycle. You'll keep them engaged—and set your organization apart.`,
    image: step2SVG
  },
  {
    title: 'Grow your sales.',
    text: `The Arkestro supplier directory connects you to more buyers in more places around\n
     the world.`,
    image: step3SVG
  }
];

export default steps;
