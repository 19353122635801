import freeEmailDomains from 'free-email-domains';

// Regex copied from https://emailregex.com/
// eslint-disable-next-line max-len, no-useless-escape
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// very simply checks that the email matches pattern [something]@[something]
export const isValidEmail = email => EMAIL_REGEX.test(email.toLowerCase());

// pulled from https://www.bennadel.com/blog/207-ask-ben-extracting-parts-of-an-email-address-in-javascript.htm
export const emailParts = email => {
  // Set up a default structure with null values
  // incase our email matching fails.
  const objParts = {
    user: null,
    domain: null,
    tld: null
  };

  // Get the parts of the email address by leveraging
  // the String::replace method. Notice that we are
  // matching on the whole string using ^...$ notation.
  email.replace(new RegExp('^(.+)@(.+)\\.(\\w+)$', 'i'), ($0, $1, $2, $3) => {
    objParts.user = $1;
    objParts.domain = $2;
    objParts.tld = $3;
  });

  // Return the "potentially" updated parts structure.
  return objParts;
};

export const isFreeEmail = (email, allowedList = []) => {  
  // If the email is in the allowed list, it may be a free email but we will allow it.
  // The allowedList should be a list of emails that come from existing records in our DB.
  if (allowedList.map(allowedEmail => allowedEmail.toLowerCase()).includes(email.toLowerCase())) return false;

  const parts = emailParts(email);
  const fullEmailDomain = `${parts.domain}.${parts.tld}`;

  return freeEmailDomains.includes(fullEmailDomain);
};

export const doesEmailMatchDomain = (email, domain) => {
  if (!email || !domain || domain.length === 0) return false;

  return domain.indexOf(emailParts(email).domain) > -1;
};

export const doesEmailMatchInDomains = (email, domainList) => {
  if (!domainList || domainList.length === 0) return false;

  return domainList.some(domain => doesEmailMatchDomain(email, domain));
};

export const emailStatusMap = t => ({
  accepted: { text: t('email_status.accepted'), color: '#045300' },
  not_answered: { text: t('email_status.not_answered'), color: '#a67300' },
  rejected: { text: t('email_status.rejected'), color: '#cd4141' },
  deactivated: { text: t('email_status.deactivated'), color: '#9b9ea8' },
  bounced: { text: t('email_status.bounced'), color: '#d47000' }, // $medium-orange
  undefined: { text: t('email_status.undefined'), color: '#9b9ea8' } // not used, but a safety net
});
