import axios from 'axios';
import { MULTIPART_FORM_DATA_HEADER, validateFileType } from '@utils/FileType';
import { ApiUrl, ParseGeneralResponse } from './ApiUtils';

export const updateBidDocument = async (t, binaryTypesAllowed, data) => {
  const {
    requestId,
    id,
    file,
    name,
    description,
    submission_required: isRequired,
    approval_required: requiresApproval,
    will_accept_submissions: willAcceptSubmissions,
    blocks_access: blocksAccess,
    consent_required: consentRequired,
    include_vendor_documents: includeVendorDocs
  } = data;
  const url = ApiUrl(`/bid_requests/${requestId}/bid_documents/${id}`);
  const formData = new FormData();

  formData.append('name', name);
  formData.append('description', description);
  formData.append('submission_required', isRequired);
  formData.append('approval_required', requiresApproval);
  formData.append('will_accept_submissions', willAcceptSubmissions);
  formData.append('include_vendor_documents', includeVendorDocs);
  formData.append('blocks_access', blocksAccess);
  formData.append('consent_required', consentRequired);

  try {
    if (data.file) {
      await validateFileType({ t, file, binaryTypesAllowed });
      formData.append('document', file);
    }
    const response = await axios.put(url, formData, { headers: MULTIPART_FORM_DATA_HEADER });

    return ParseGeneralResponse(true, response, {
      responseDataKeys: {
        bid_document: 'bid_document'
      }
    });
  } catch (error) {
    return ParseGeneralResponse(false, error, {});
  }
};

export const addConsentBidDocument = data => {
  const {
    requestId,
    id,
  } = data;
  const url = ApiUrl(`/bid_requests/${requestId}/bid_documents/add_consent/${id}`);

  return axios.post(url)
    .then(response => Promise.resolve(ParseGeneralResponse(true, response, {
      responseDataKeys: {
        bid_documents: 'bid_documents',
        missing_required_documents: 'missing_required_documents',
        not_consented_and_blocked: 'not_consented_and_blocked'
      }
    })))
    .catch(error => Promise.resolve(ParseGeneralResponse(false, error, {})));
};
