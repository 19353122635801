import { Button, type ButtonProps } from '@arkestro/arkestro-design-system';

export const PrimaryButton = ({
  block,
  children,
  className,
  disabled,
  ghost,
  loading,
  onClick,
  shape,
  size,
  style,
  type = 'primary',
  htmlType = 'button',
}: ButtonProps) => (
  <Button
    block={block}
    className={className}
    disabled={disabled}
    loading={loading}
    ghost={ghost}
    onClick={onClick}
    shape={shape}
    size={size}
    style={style}
    type={type}
    htmlType={htmlType}
  >
    {children}
  </Button>
);
