import PropTypes from 'prop-types';
import { Col, Row, Select } from '@arkestro/arkestro-design-system';
import useTranslation from 'next-translate/useTranslation';
import InputField from 'components/shared/forms/InputField/InputField';
import InputPhone from 'components/shared/forms/InputPhone/InputPhone';
import { parseErrorsIntoString } from 'utils/password';
import { prettyBusinessRegionsMap } from '@utils/regions';
import styles from 'components/shared/forms/InputField/InputField.module.scss';
import { TooltipInfoIcon } from '@components/shared';

const BUSINESS_REGION_WIDTH = 300;

const readOnlySections = (fieldLists, listsName) => fieldLists.map(fieldList => (
  <section key = {`section-${listsName}-${fieldList[0].key}`} className='section-container'>
    <Row>
      {fieldList.map(field => (
        <Col key={`col-${field.key}`} className='read-only' sm={24 / fieldList.length}>
          <label className='label' htmlFor={field.key}>{field.label}</label>
          <span className='text' id={field.key}><strong>{field.value}</strong></span>
        </Col>
      ))}
    </Row>
  </section>
));

const inputSections = (fieldLists, onChange, listsName, t) => fieldLists.map(fieldList => {
  const businessRegionMap = prettyBusinessRegionsMap(t);

  return (
    <section key={`section-${listsName}-${fieldList[0].key}`} className='section-container'>
      <Row gutter={16}>
        {fieldList.map(inputField => {
          const error = parseErrorsIntoString(inputField.errors);
          const colSizes = inputField.colSizes || { sm: 24, md: 24 / fieldList.length };

          if (inputField.key === 'phone') {
            return (
              <Col key={`col-${inputField.key}`} sm={24 / fieldList.length}>
                <InputPhone value={inputField.value} onChange={onChange} />
              </Col>
            );
          }

          if (inputField.key === 'businessRegion') {
            return (
              <Col key={`col-${inputField.key}`} sm={24 / fieldList.length}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <label
                    className={styles.label}
                    style={{ marginRight: 5 }}
                    htmlFor='businessRegion'
                  >
                    {t('general.business_region')}
                  </label>
                  <TooltipInfoIcon title={t('tooltip.business_region.description')} />
                </div>
                <Select
                  name='businessRegion'
                  onChange={val => onChange({ target: { name: 'businessRegion', value: val } })}
                  options={[
                    { value: 'na', label: businessRegionMap.na },
                    { value: 'emea', label: businessRegionMap.emea },
                    { value: 'latam', label: businessRegionMap.latam },
                    { value: 'apac', label: businessRegionMap.apac },
                    { value: '', label: '' },
                  ]}
                  style={{ width: BUSINESS_REGION_WIDTH }}
                  value={inputField.value}
                />
              </Col>
            );
          }

          return (
            <Col key={`col-${inputField.key}`} {...colSizes}>
              <InputField
                name={inputField.key}
                labelText={inputField.label}
                value={inputField.value}
                type={inputField.type}
                onChange={onChange}
                required={inputField.required}
                error={error}
              />
            </Col>
          );
        })}
      </Row>
    </section>
  );
});

const ProfileFields = ({
  isEditingReadOnlyFields,
  readOnlyFields,
  normalFields,
  isEditingFields,
  onChange,
  isEditing
}) => {
  const { t } = useTranslation('common');

  return (
    <>
      {isEditing && readOnlySections(isEditingReadOnlyFields, 'isEditReadOnly')}
      {readOnlySections(readOnlyFields, 'readOnly')}
      {inputSections(normalFields, onChange, 'input', t)}
      {isEditing && inputSections(isEditingFields, onChange, 'isEditInput', t)}
    </>
  );
};

ProfileFields.propTypes = {
  isEditingReadOnlyFields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())),
  isEditingFields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())),
  readOnlyFields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())),
  normalFields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())),
  onChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool
};

ProfileFields.defaultProps = {
  isEditingReadOnlyFields: [],
  isEditingFields: [],
  readOnlyFields: [],
  normalFields: [],
  isEditing: false
};

export default ProfileFields;
