import useTranslation from 'next-translate/useTranslation';

import { TooltipInfoIcon } from '@components/shared';

import { SpendTypesTooltipContent } from './SpendTypesTooltipContent/SpendTypesTooltipContent';

const SpendTypesTooltipLine = () => {
  const { t } = useTranslation('common');

  return (
    <div className='request-onboarding-tooltip-line'>
      {t('tooltip.onboarding.spend_types')}
      &nbsp;
      <TooltipInfoIcon
        title={<SpendTypesTooltipContent />}
        placement='bottom'
        overlayStyle={{ minWidth: 320 }}
      />
    </div>
  );
};

export default SpendTypesTooltipLine;
