import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Badge, Button, Popover } from '@arkestro/arkestro-design-system';
import { BellOutlined } from '@ant-design/icons';
import useTranslation from 'next-translate/useTranslation';

import { NotificationsContext } from '@components/providers/NotificationsProvider/NotificationsProvider';
import { GeneralContext } from '@components/providers/GeneralProvider/GeneralProvider';
import NotificationsDropdown from '@components/layout/Header/Notifications/NotificationsDropdown/NotificationsDropdown';
import { Notification } from '@components/layout/Header/Notifications/types';

const NotificationsDropdownButton = (): JSX.Element => {
  const { t } = useTranslation('common');
  const {
    companyInvites = [],
    bidAcceptances = [],
    getAndSetCompanyInvites,
    getAndSetBidAcceptances
  } = useContext(GeneralContext);
  const {
    streamNotificationsFeed,
    unseenCount = 0,
    setProperty,
  } = useContext(NotificationsContext);
  const [notificationsList, setNotificationsList] = useState([]);
  const [totalUnseenCount, setTotalUnseenCount] = useState(0);

  useEffect(() => {
    // Set the total count of notitications that are not 'seen' (e.g. new notifications)
    setTotalUnseenCount(companyInvites.length + bidAcceptances.length + unseenCount);
  }, [bidAcceptances, companyInvites, unseenCount]);

  // Effects to run once for adding to popover list
  useEffect(() => {
    getAndSetCompanyInvites();
    getAndSetBidAcceptances();
  }, []);

  // Effects to run when receiving real-time notifications for adding to popover list
  useEffect(() => {
    if (streamNotificationsFeed) {
      streamNotificationsFeed.get().then((response: any) => {
        // response.unseen includes read notifications which we don't show
        setProperty({
          unseenCount: response.results.filter((result: any) =>
            !result.is_read && !result.is_seen).length
        });

        // Extract the activities information,
        // which constitutes what notification we want to send from the Stream object we receive.
        // Based on how our grouping for activities is in Stream, the activities array should only have one element,
        // so we flatten out to a single list with all of the keys, including the is_read and is_seen keys.
        setNotificationsList(
          response.results.filter((result: any) => !result.is_read)
            .map((result: any) => ({
              ...result.activities[0],
              ...{
                is_read : result.is_read,
                is_seen : result.is_seen,
                activity_id: result.id,
                created_at: result.created_at
              }
            }))
        );
      });
    }
  }, [streamNotificationsFeed, unseenCount]);

  return (
    <Popover
      overlayClassName='no-padding'
      content={
        <NotificationsDropdown
          list={notificationsList}
          setList={setNotificationsList as Dispatch<SetStateAction<Notification[]>>}
        />
      }
      trigger='click'
      placement='bottomRight'
    >
      <Badge
        dot
        title={t('notifications.new_notifications')}
        count={totalUnseenCount}
        data-testid='notifications-dropdown-badge'
        offset={[-8, 6]}
      >
        <Button
          type='text'
          title={t('notifications.actions.open_list')}
          data-testid='notifications-dropdown-button'
          // Styles need to be specified inline in order to apply to button icons
          icon={<BellOutlined style={{ fontSize: '24px' }} />}
          size='large'
        />
      </Badge>
    </Popover>
  );
};

export default NotificationsDropdownButton;
