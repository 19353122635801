import { useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { Button, Modal } from '@arkestro/arkestro-design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DeeplinkLoginForm from './DeeplinkLoginForm';

const DeeplinkLogin = () => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const requestId = router.query.id;
  const [showAuthModal, setShowAuthModal] = useState(false);
  const { canUseDeeplinkLogin } = useFlags();

  const handleOnModalClose = () => {
    setShowAuthModal(false);
  };
  const handleOnModalOpen = () => {
    setShowAuthModal(true);
  };

  const logo = (
    <Image
      width={126}
      height={32.2}
      layout='intrinsic'
      className="login-logo"
      src="https://bidops-public.s3.amazonaws.com/assets/logos/arkestro_logo_color%402x.png"
      alt='Logo'
    />
  );

  return (
    <>
      {canUseDeeplinkLogin ?
        <Button
          type="link"
          onClick={handleOnModalOpen}
        >
          {t('login.deeplink_login')}
        </Button>
        : <div />
      }

      {showAuthModal &&
          <Modal
            footer={null}
            centered
            closeIcon={null}
            open
            destroyOnClose
            onCancel={handleOnModalClose}
            title={logo}
            width='450px'
          >
            <DeeplinkLoginForm onModalClose={handleOnModalClose} id={requestId} />
          </Modal>
      }
    </>
  );
};

export default DeeplinkLogin;
