import PropTypes from 'prop-types';
import { MenuOutlined } from '@ant-design/icons';
import style from './Collapser.module.scss';

const Collapser = ({ toggleSideMenu }) => {
  // When viewing Sidekiq, no menu will appear.
  // Empty div used for alignment
  if (!toggleSideMenu) return <div />;

  return (
    <MenuOutlined
      data-testid="icon-menu"
      onClick={toggleSideMenu}
      className={style.icon}
    />
  );
};

Collapser.propTypes = {
  toggleSideMenu: PropTypes.func
};

Collapser.defaultProps = {
  toggleSideMenu: undefined
};

export default Collapser;
