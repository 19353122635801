import { CSSProperties } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, TooltipProps } from '@arkestro/arkestro-design-system';

interface TooltipInfoIconProps extends TooltipProps {
  iconClassName?: string;
  iconStyle?: CSSProperties;
}

export const TooltipInfoIcon = ({
  className,
  iconClassName,
  iconStyle,
  title,
  ...props
}: TooltipInfoIconProps) => {

  const iconStyles = { marginLeft: '5px', ...iconStyle };

  return (
    <Tooltip className={className} title={title} {...props}>
      <InfoCircleOutlined className={iconClassName} style={iconStyles} />
    </Tooltip>
  );
};
