import { cloneElement } from 'react';
import { PopConfirm as ADSPopConfirm, PopConfirmProps as ADSPopConfirmProps } from '@arkestro/arkestro-design-system';
import useTranslation from 'next-translate/useTranslation';

export interface PopConfirmProps extends ADSPopConfirmProps {
  dangerMode?: boolean;
  subtitle?: string | JSX.Element | undefined;
  disableChildren?: boolean;
};

export const PopConfirm = ({
  cancelText,
  children,
  dangerMode = false,
  dataTestId,
  disabled,
  icon,
  okText,
  onConfirm,
  subtitle,
  title,
  disableChildren,
  ...attrs
}: PopConfirmProps) => {
  const { t } = useTranslation('common');
  const cancelLabel = cancelText || t('general.cancel');
  const okLabel = okText || t('general.yes');
  const titleText = title || t('general.are_you_sure');

  const CustomOkButtonProps = {
    danger: dangerMode
  };

  // If confirmation is disabled, then we still want to trigger the onConfirm function
  // This is useful for cases where we want to trigger the onConfirm function without showing the confirmation modal
  // For example, the user is changing to another tab and there are no unsaved changes
  if (disabled && children) {

    // type string is not a valid type for cloneElement, it will through an Unhandled Runtime Error
    // Adding this console error to provide a better developer experience
    if ((typeof children === 'string')) {
      console.error('PopConfirm: The children prop should not be a string when `disabled` is true');
    }

    // cloneElement is used to pass the onClick event to the children
    return cloneElement(children as JSX.Element, { disabled: disableChildren, onClick: onConfirm });
  };

  const getContent = () => {
    return (
      subtitle && <div className='subtitle'>{subtitle}</div>
    );
  };

  return (
    <ADSPopConfirm
      {...attrs}
      cancelText={cancelLabel}
      dataTestId={dataTestId}
      description={getContent()}
      disabled={disabled}
      onConfirm={onConfirm}
      okButtonProps={CustomOkButtonProps}
      okText={okLabel}
      title={titleText}
      icon={icon}
    >
      {/* IMPORTANT: For some reason this ADS component is messing with the CSS of the direct children,
          to fix this we need this fragment, please don't remove it. */}
      <>{children}</>
    </ADSPopConfirm>
  );
};
