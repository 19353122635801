class LaunchDarklyService {
  static configureUser = (ldClient, user) => {
    if (!ldClient || !user?.id) return;

    // v3.x+ uses Contexts: https://docs-stg.launchdarkly.com/contexts-eap/home/contexts
    const context = {
      kind: 'user',
      key: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      name: user.displayname,
      email: user.email,
      subdomain: user.subdomain,
      role: user.role,
      category: user.category,
      isBidopsTeamUser: user.is_bidops_team_user,
      planType: user.plan_type,
      trueUserEmail: user.true_user?.email,
      trueUserId: user.true_user?.id,
      isSelfServe: user.is_self_serve,
      companyName: user.company?.name
    };

    // Attach user to launch darkly
    ldClient.identify(context);
  }
}

export default LaunchDarklyService;
