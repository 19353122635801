const ENVIRONMENT = process.env.NEXT_PUBLIC_ARKESTRO_ENV;

// To test this service locally, add 'development' to the array below and do not commit.
const VALID_ENVIRONMENTS = ['dev', 'stage', 'sandbox', 'prod'];

// https://developers.intercom.com/installing-intercom/web/installation/#installing-the-messenger-for-regional-data-hosted-customers
const US_INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
const EU_INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_EU_APP_ID;

export const collapsedIntercomLeft = 15;
export const expandedIntercomLeft = 30;
const verticalPadding = 90;

// https://developers.intercom.com/installing-intercom/web/installation/#standard-installation
class IntercomService {
  static initialize = () => {
    if (typeof window === 'undefined' || !VALID_ENVIRONMENTS.includes(ENVIRONMENT)) return;
    this.loadIntercomScript(US_INTERCOM_APP_ID);
    this.boot(US_INTERCOM_APP_ID);
  };

  static boot = (appId = US_INTERCOM_APP_ID) => {
    if (typeof window === 'undefined' || !window.Intercom || !VALID_ENVIRONMENTS.includes(ENVIRONMENT)) return;
    window.Intercom('boot', { app_id: appId });
  };

  static loadIntercomScript = appId => {

    (function (){const w=window;
      const ic=w.Intercom;

      if (typeof ic==='function'){ic('reattach_activator');ic('update',w.intercomSettings);} else {const d=document;
        const i=function (){i.c(arguments);};

        i.q=[];i.c=function (args){i.q.push(args);};w.Intercom=i;const l=function (){const s=d.createElement('script');

          s.type='text/javascript';
          s.async=true;
          s.src=`https://widget.intercom.io/widget/${appId}`;
          const x=d.getElementsByTagName('script')[0];

          x.parentNode.insertBefore(s, x);};

        if (document.readyState==='complete'){
          l();
        } else if (w.attachEvent){
          w.attachEvent('onload',l);
        } else {
          w.addEventListener('load',l,false);
        }
      }
    })();
  };

  static configureUser = (options = {}) => {
    if (!VALID_ENVIRONMENTS.includes(ENVIRONMENT)) return;
    if (typeof window === 'undefined' || !VALID_ENVIRONMENTS.includes(ENVIRONMENT) || !options.currentUser?.id) return;

    const user = options.currentUser.impersonating ? options.currentUser.true_user : options.currentUser;

    if (!user?.id) return;

    const appId = user.emea ? EU_INTERCOM_APP_ID : US_INTERCOM_APP_ID;

    // We could be switching Intercom Instances (EU vs AU vs US) so we need to shut down the current instance
    // to avoid any authorization errors.
    window.Intercom('shutdown');
    window.Intercom('boot', this.userSettings(user, appId, options.collapsed));
  };

  // Load new User object into Intercom
  // This could be going from an anonymous user (logged out) to an identified (logged in) user.
  static userSettings = (user, appId, collapsed) => ({
    user_hash: user.intercom_hmac,
    api_base: user.intercom_api_base,
    app_id: appId,
    name: user.name,
    user_id: user.id,
    email: user.email,
    created_at: user.created_at,
    company: user.company,
    userType: user.category,
    vertical_padding: verticalPadding,
    horizontal_padding: collapsed ? collapsedIntercomLeft : expandedIntercomLeft,
    subdomain: user.subdomain,
    job_title: user.job_title,
    role: user.role,
    is_self_serve: user.is_self_serve,
    is_demo_account: user.is_demo_account,
    plan_type: user.plan_type,
    subdomain_manager: user.is_company_owner,
    is_superadmin_session: user.impersonating,
    superadmin_user: user.true_user?.email,
    impersonated_user_email: user.impersonating ? user.email : null,
  });
}

export default IntercomService;
