const {
  linkTypeMap,
  createClient: createClientOriginal,
  generateGraphqlOperation,
  assertSameVersion,
} = require('@genql/runtime')
var typeMap = linkTypeMap(require('./types.cjs'))

var version = '2.10.0'
assertSameVersion(version)

module.exports.version = version

module.exports.createClient = function(options) {
  options = options || {}
  var optionsCopy = {
    url: 'http://localhost:3000/api/v1/graphql/',
    queryRoot: typeMap.Query,
    mutationRoot: typeMap.Mutation,
    subscriptionRoot: typeMap.Subscription,
  }
  for (var name in options) {
    optionsCopy[name] = options[name]
  }
  return createClientOriginal(optionsCopy)
}

module.exports.enumBidRequestSortEnum = {
  requestType: 'requestType',
  id: 'id',
  name: 'name',
  isTest: 'isTest',
  state: 'state',
  responseCount: 'responseCount',
  currentRoundNumber: 'currentRoundNumber',
  startTime: 'startTime',
  endTime: 'endTime',
  nextDeadLine: 'nextDeadLine',
  company: 'company',
  creator: 'creator',
  businessUnitName: 'businessUnitName',
  owners: 'owners',
}

module.exports.enumBidResponseSortEnum = {
  id: 'id',
  userId: 'userId',
  bidRequestId: 'bidRequestId',
  createdAt: 'createdAt',
}

module.exports.enumCompanyStatusEnum = {
  active: 'active',
  inactive: 'inactive',
  field_trial: 'field_trial',
  demo_or_test: 'demo_or_test',
  anonymized: 'anonymized',
}

module.exports.generateQueryOp = function(fields) {
  return generateGraphqlOperation('query', typeMap.Query, fields)
}
module.exports.generateMutationOp = function(fields) {
  return generateGraphqlOperation('mutation', typeMap.Mutation, fields)
}
module.exports.generateSubscriptionOp = function(fields) {
  return generateGraphqlOperation('subscription', typeMap.Subscription, fields)
}
module.exports.everything = {
  __scalar: true,
}

var schemaExports = require('./guards.cjs')
for (var k in schemaExports) {
  module.exports[k] = schemaExports[k]
}
