// Color name here should line up with variable names from _variables.scss
import { gray13, green7, blue6, orange6, orange8, colorErrorActive, colorSuccessText } from
  '@arkestro/arkestro-design-system';

const colors = {
  themeIconColor: {
    base: '#000',
    inverted: '#fff'
  },
  grey: {
    lightest: '#f6f6f6',
    lighter: '#e1e3e6',
    mediumDark: '#6c6f78',
    dark: '#242528'
  },
  green: {
    base: gray13,
    medium: '#358a61',
    background: '#edf5f4'
  },
  red: {
    base: '#be0000',
    dark: '#9e0000'
  },
  yellow: {
    dark: '#a67300',
    background: '#fffbee'
  },
  statuses: {
    null: '#aaaaaa',
    undefined: '#aaaaaa',
    not_invited: '#aaaaaa',
    'not invited': '#aaaaaa',
    'Not Invited': '#aaaaaa',
    draft: '#a67300',
    Draft: '#a67300',
    pending: '#a67300',
    Pending: '#a67300',
    accepted: '#045300',
    Accepted: '#045300',
    approved: '#045300',
    Approved: '#045300',
    bounced: '#d47000',
    Bounced: '#d47000',
    rejected: '#cd4141',
    Rejected: '#cd4141',
    declined: '#cd4141',
    Declined: '#cd4141',
    preferred: '#1D93D3',
    Preferred: '#1D93D3',
    exists: '#4060FF',
    Exists: '#4060FF'
  },
  requestStates: {
    response_submitted: 'rgba(255, 197, 21, 1)',
    responseSubmitted: 'rgba(255, 197, 21, 1)',
    draft: 'rgba(137, 139, 145, 1)',
    open: 'rgba(63, 207, 193, 1)',
    open_for_questions: '#389E0D',
    openForQuestions: '#389E0D',
    open_for_bidding: 'rgba(97, 184, 209, 1)',
    openForBidding: 'rgba(97, 184, 209, 1)',
    closed: 'rgba(29, 147, 211, 1)',
    awarded: 'rgba(64, 96, 255, 1)',
    ready_to_award: 'rgba(22, 119, 255, 1)',
    readyToAward: 'rgba(22, 119, 255, 1)',
    unawarded: 'rgba(250, 140, 22, 1)',
    notAwarded: 'rgba(250, 140, 22, 1)',
    award_pending: 'rgba(22, 119, 255, 1)',
    awardPending: 'rgba(22, 119, 255, 1)',
    // #TODO: Remove this when we remove the enableAwardRound flag
    enableAwardRoundStates: {
      response_submitted: green7,
      responseSubmitted: green7,
      draft: gray13,
      open: green7,
      open_for_questions: green7,
      openForQuestions: green7,
      open_for_bidding: green7,
      openForBidding: green7,
      closed: gray13,
      awarded: gray13,
      ready_to_award: blue6,
      readyToAward: blue6,
      award_pending: blue6,
      awardPending: blue6,
      unawarded: orange6,
      notAwarded: orange6,
    }
  },
  requestTypes: {
    proposal: '#4060ff',
    proposals: '#4060ff',
    information: '#be00a0',
    quote: '#1d93d3',
    quotes: '#1d93d3',
    purchase_request: '#246847',
    purchaseRequest: '#246847'
  },
  requestStatusOverviewColors: {
    draft: gray13,
    response_submitted: orange8,
    open_for_questions: green7,
    open_for_bidding: green7,
    closed: gray13,
    awarded: gray13,
    unawarded: orange6,
    ready_to_award: blue6,
    award_pending: blue6
  }
};

// return grey, red, or green depending on the value
export const adjustmentColor = value => {
  let color = '#7e7e80'; // grey

  if (value > 0) color = '#246847'; // green
  if (value < 0) color = '#BE0000'; // red

  return color;
};

const DEFAULT_COLOR_LIMITS = [0.5, 0.8];

// Take a value and returns its corresponding color. Assumes 0 is bad e.g. "red"
// @param value [Float|Number] Value to lookup
// @param shouldReturnCode [Boolean] When true, returns the hex code
// @param limits [Array] Color breakpoints. Assumes cap of 1.0
// @return [String] Color as string name by default or hex code with flag enabled
export const colorFromValue = (value, shouldReturnCode = false, limits = DEFAULT_COLOR_LIMITS) => {
  let color = { name: 'bad', hex: colorErrorActive };

  if (value > limits[0]) color = { name: 'notice', hex: orange8 };
  if (value > limits[1]) color = { name: 'good', hex: colorSuccessText };

  return shouldReturnCode ? color.hex : color.name;
};

export default colors;
