import { useRouter } from 'next/router';
import { ReactElement, useState, useEffect, useCallback } from 'react';
import { DEFAULT_LOTS_CHANGES } from 'components/bid_request/RequestProvider/RequestProvider';
import OnBlurModal from './OnBlurModal';

export const ROUTE_CHANGE_ABORT_ERROR = 'routeChangeAbort';

export interface UnsavedChangesDialogProps {
  shouldConfirmLeave: boolean;
  title?: string;
  subtitle?: string;
  confirmText?: string;
  declineText?: string;
  setRequestProperty?: (_arg: any) => void;
}

const UnsavedChangesDialog = ({
  shouldConfirmLeave,
  title,
  subtitle,
  confirmText,
  declineText,
  setRequestProperty,
}: UnsavedChangesDialogProps): ReactElement<UnsavedChangesDialogProps> => {
  const [shouldShowLeaveConfirmDialog, setShouldShowLeaveConfirmDialog] = useState(false);
  const [nextRouterPath, setNextRouterPath] = useState<string>();

  const router = useRouter();

  const onRouteChangeStart = useCallback(
    (nextPath: string) => {
      if (!shouldConfirmLeave) {
        return;
      }

      setShouldShowLeaveConfirmDialog(true);
      setNextRouterPath(nextPath);

      throw ROUTE_CHANGE_ABORT_ERROR;
    },
    [shouldConfirmLeave]
  );

  const onRejectRouteChange = () => {
    setNextRouterPath(null);
    setShouldShowLeaveConfirmDialog(false);
  };

  const removeListener = () => {
    router.events.off('routeChangeStart', onRouteChangeStart);
  };

  const onConfirmRouteChange = () => {
    setShouldShowLeaveConfirmDialog(false);
    // simply remove the listener here so that it doesn't get triggered when we push the new route.
    // This assumes that the component will be removed anyway as the route changes
    if (typeof setRequestProperty === 'function') {
      setRequestProperty({ 'lotsChanges': DEFAULT_LOTS_CHANGES });
    }
    removeListener();
    router.push(nextRouterPath);
  };

  useEffect(() => {
    router.events.on('routeChangeStart', onRouteChangeStart);

    return removeListener;
  }, [onRouteChangeStart]);

  return (
    <OnBlurModal
      title={title}
      subtitle={subtitle}
      confirmText={confirmText}
      declineText={declineText}
      visible={shouldShowLeaveConfirmDialog}
      onConfirm={onConfirmRouteChange}
      onCancel={onRejectRouteChange}
    />
  );
};

export default UnsavedChangesDialog;
