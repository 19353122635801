import { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from '@providers/UserProvider/UserProvider';
import {
  FULL_LOGO_WHITE_URL,
  FULL_LOGO_BLACK_URL,
} from 'utils/constants';

const LogoImage = ({ useDark, useMyCompanyLogo, imageStyle, fallbackToBidops }) => {
  const currentUser = useContext(UserContext);
  const company = useMyCompanyLogo ? currentUser?.company : null;

  const defaultImageSource = useDark
    ? FULL_LOGO_BLACK_URL
    : FULL_LOGO_WHITE_URL;

  const useWordmark = (company !== null && company !== undefined && company.wordmark_url !== null);
  const imageSource = useWordmark
    ? company.wordmark_url
    : (fallbackToBidops && defaultImageSource);

  if (!imageSource) return null;

  return (
    <img
      src={imageSource}
      style={imageStyle}
      className={`logo ${!useWordmark && fallbackToBidops}`}
      alt='Company Logo'
      data-testid='logo-image'
    />
  );
};

LogoImage.propTypes = {
  useDark: PropTypes.bool,
  useMyCompanyLogo: PropTypes.bool,
  imageStyle: PropTypes.shape(),
  fallbackToBidops: PropTypes.bool,
};

LogoImage.defaultProps = {
  useDark: false,
  useMyCompanyLogo: false,
  fallbackToBidops: true,
  imageStyle: {},
};

export default LogoImage;
