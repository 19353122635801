import { createContext, ReactNode, useContext, useState, useMemo } from 'react';

export interface Route {
  title: string | ReactNode;
  path?: string
}

export type BreadCrumbRoutes = {
  [key: string]: Route[];
}

interface BreadCrumbState {
  [key: string]: BreadCrumbRoutes;
}

export type ContextType = {
  data: BreadCrumbState;
  getBreadCrumbs: (_parent: string, _pathname: string) => Route[];
  setBreadCrumbs: (_parent: string, _pathname: string, _routes: Route[]) => void;
}

export const BreadCrumbsContext = createContext<ContextType | undefined>(undefined);

interface BreadCrumbsProviderProps {
  children: ReactNode;
}

export const BreadCrumbsProvider = ({ children }: BreadCrumbsProviderProps) => {
  const [data, setData] = useState<BreadCrumbState>({} as BreadCrumbState);

  const getBreadCrumbs = (parentName: string, pathname: string): Route[] => {
    if (!parentName?.length || !pathname?.length) return [];
    if (!data || !data[parentName] || !data[parentName][pathname]) return [];
    return data[parentName][pathname];
  };

  const setBreadCrumbs = (parentName: string, pathname: string, routes: Route[]): void => {
    setData((prev: BreadCrumbState): BreadCrumbState => {
      const creadCrumbRoutes: BreadCrumbRoutes = { [pathname]: routes };

      return {
        ...prev,
        [parentName]: creadCrumbRoutes
      };
    });
  };

  const memoizedValue: ContextType = useMemo(() => {
    const context: ContextType = { data, getBreadCrumbs, setBreadCrumbs };

    return context;
  }, [data]);

  return (
    <BreadCrumbsContext.Provider value={memoizedValue}>
      {children}
    </BreadCrumbsContext.Provider>
  );
};

// Use to set breadcrumbs for a child component of the parent bread crumb component (BreadCrumbs.tsx)
export const useBreadCrumbs = (): ContextType => {
  const context = useContext(BreadCrumbsContext);

  if (!context) {
    throw new Error('useBreadCrumbs must be used within a BreadCrumbsProvider');
  }

  return context;
};
