import { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@arkestro/arkestro-design-system';
import withTranslation from 'next-translate/withTranslation';

const Errors = errors => (
  <div>
    { errors.map((err, i) => (
      <div key={i}>{err}</div>
    ))}
  </div>
);

class FormErrors extends Component {
  render () {
    const errors = this.props.errors || [];

    if (errors.length < 1) {
      return [];
    }
    return [
      <Alert
        key='alert-msg'
        style={{ marginBottom: 15 }}
        message={this.props.i18n.t('errors.errors')}
        description={Errors(errors)}
        type='error'
      />,
    ];
  }
}

FormErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  i18n: PropTypes.shape().isRequired

};

FormErrors.defaultProps = {
  errors: [],
};

export default withTranslation(FormErrors, 'common');
