import { createContext, ReactNode, useState, useEffect } from 'react';
import { isCollapsedFromStorage, storeCollapsed } from '@providers/LayoutProvider/utils';
import { Layout } from 'antd-v3';

export interface LayoutContextData {
  toggleSideMenu: () => void;
  collapsed: boolean;
}

export const contextDefaultValue: LayoutContextData = {
  toggleSideMenu: () => {},
  collapsed: isCollapsedFromStorage()
};

const useLayoutContextValue = (): LayoutContextData => {
  const [collapsed, setCollapsed] = useState<boolean>(isCollapsedFromStorage());
  const toggleSideMenu = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    storeCollapsed(collapsed);
  }, [collapsed]);
  
  return {
    toggleSideMenu,
    collapsed
  };
};

export const LayoutContext = createContext<LayoutContextData>(contextDefaultValue);
type Props = {
  children: ReactNode;
};

export const LayoutProvider = ( { children }: Props) => {
  const contextValue = useLayoutContextValue();

  return (
    <LayoutContext.Provider value={contextValue}>
      <Layout hasSider className='main-layout' style={{ minHeight: '100vh' }}>
        {children}
      </Layout>
    </LayoutContext.Provider>
  );
};
