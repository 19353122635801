export const fullName = (record, ignoreEmail = false) => {
  if (!record) return '';

  const { first_name: firstName } = record;

  if (!firstName && ignoreEmail) return '';
  
  if (!firstName) return record.email;

  return `${firstName} ${record.last_name}`.replace(/null/g, '').trim();
};

export const isValidSupplierOrgName = name => name.trim().length > 0 && name.trim() !== 'Supplier';
