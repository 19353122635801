import Routes from '@services/Routes';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { useContext } from 'react';
import { Button, combineClassNames } from '@arkestro/arkestro-design-system';
import { stopImpersonating } from 'api/AuthApi';
import { alertMessage } from 'utils/common';
import { UserContext } from 'components/providers/UserProvider/UserProvider';
import style from './SuperAdminBar.module.scss';

const SuperAdminBar = ({ isFixed }) => {
  const { t } = useTranslation('common');
  const user = useContext(UserContext);

  if (!user.impersonating) return null;

  const handleStopImpersonating = () => {
    stopImpersonating().then(results => {
      if (results?.response?.data?.errors) {
        alertMessage(results.response.data.errors[0], 'error', 6);
        return;
      }

      // Reloading the page as quick hack to auto reset stream client user
      window.location = Routes.SUPER_ROOT_PATH;
    })
      .catch(() => {
        alertMessage(t('errors.stop_impersonating'), 'error', 6);
      });
  };

  const buttonClassNames = combineClassNames(style.warning, isFixed ? 'fixed' : '');

  return (
    <Button
      className={buttonClassNames}
      danger
      type='primary'
      block
      onClick={handleStopImpersonating}
    >
      {t('general.super_admin_login_button')}
    </Button>
  );
};

SuperAdminBar.propTypes = {
  isFixed: PropTypes.bool
};

SuperAdminBar.defaultProps = {
  isFixed: false
};

export default SuperAdminBar;
