import gql from 'graphql-tag';
import { StreamChat } from 'stream-chat';
import { connect } from 'getstream';
import AppConfig from 'config/AppConfig';

const GET_CHAT_TOKEN_QUERY = gql`
    query GetStreamChatToken {
      streamChatToken
    }
`;

const CHAT_OPTIONS = { timeout: 15000 };

// passing in App component for updating state
export const setupStream = async (provider, gqlClient, user) => {
  const res = await gqlClient.query({ query: GET_CHAT_TOKEN_QUERY });
  const { streamChatToken } = res.data;
  const chatClient = new StreamChat(AppConfig.streamKey, CHAT_OPTIONS);

  await chatClient.connectUser({
    id: user.id.toString(),
    name: user.name,
  }, streamChatToken);

  provider.setState({
    streamChatToken,
    streamClient: chatClient
  });

  return chatClient;
};

/**
 * Creates the streamNotificationsFeed object for this user and sets it to the state object for this provider.
 * Uses the streamNotificationsKey, streamNotificationsAppID, and composite stream ID to set up the feed object.
 * The streamNotificationsFeed object can be used to GET the set of activities from stream for the user via
 * streamNotificationsFeed.get( { limit: 100 } ), which returns a promise.
 * @param provider {React.Provider} Provider to which to attach this context
 * @param unseenCount {Number} Current unseen count state
 * @param setProperty
 * @param user
 * @returns {Promise<object>} Real-time notifications subscription
 */
export const setupStreamNotifications = async (provider, unseenCount, setProperty, user) => {
  // User may not exist when on deeplink
  if (!user?.id ) return;

  const userToken = user.stream_user_token;
  const userStreamClient = connect(
    AppConfig.streamNotificationsKey,
    null, // No secret objects on client side, would use this for server-side NodeJS
    AppConfig.streamNotificationsAppID
  );

  const userStreamNotificationsId = user.stream_notifications_id;
  const userNotificationsFeed = userStreamClient.feed('notification', userStreamNotificationsId, userToken);

  /**
   * This callback will be called when the websocket receives an update, the data
   * will contain the most recent activity object.
   * @param data Activity object from stream
   */
  function callback () {
    setProperty({ unseenCount: unseenCount + 1 });
  }

  /**
   * This callback is called when connecting to the websocket is successful.
   */
  function successCallback () {
  }

  /**
   * This callback is called when connecting to the websocket fails.
   */
  function failCallback () {
  }

  provider.setState({
    streamNotificationsFeed: userNotificationsFeed,
  });

  return userNotificationsFeed.subscribe(callback).then(successCallback, failCallback);
};
