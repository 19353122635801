import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export const getCompanyInvites = (acceptance = []) => {
  const url = ApiUrl(`/company_user_invite_acceptances?acceptance=${acceptance}`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { company_invites: 'company_invites' } }
  );
};

/**
 * Update the company invite record, mainly used for inviting suppliers to join a company's supplier DB. Will
 * also add acceptance records for other suppliers in the same organization.
 * @param id {Number} Id of the invite record
 * @param acceptance {String} Value of the acceptance
 * @returns {Object} Object containing the acceptance value in company_user_invite_acceptance key
 */
export function updateCompanyInvite (id, acceptance) {
  const url = ApiUrl(`/company_user_invite_acceptances/${id}`);

  return Call(
    'put',
    url,
    {
      acceptance
    },
    ParseGeneralResponse,
    { responseDataKeys: { company_user_invite_acceptance: 'company_user_invite_acceptance' } }
  );
}
