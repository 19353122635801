import {
  ApolloClient,
  ApolloLink,
  InMemoryCache
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';

const WEB_SOCKET_LOCALHOST = 'ws://localhost:3002';
const WEB_SOCKET_HOST = process.env.NEXT_PUBLIC_WS_HOST || WEB_SOCKET_LOCALHOST;

class ApolloService {
  constructor (user) {
    this.user = user;
    this.isInBrowser = typeof window !== 'undefined';
  }

  link = () => {
    if (!this.isInBrowser) return null;

    return ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) => (
            console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          ));
        if (networkError) console.warn(`[Network error]: ${networkError.message}`);
      }),
      this.wsLink()
    ]);
  }

  wsLink = () => {
    if (!this.isInBrowser) return null;

    return new WebSocketLink({
      uri: `${WEB_SOCKET_HOST}/graphql`,
      options: {
        reconnect: true,
        wsOptionArguments: [{
          perMessageDeflate: false
        }],
        // connectionParams as a function ensures that freshest token is used on reconnect. this was tricky to
        // figure out because there was disagreement over how to accomplish this on the repo, and it is
        // poorly documented, but i went with this solution from
        // https://github.com/apollographql/subscriptions-transport-ws/issues/171#issuecomment-348492358
        connectionParams: () => ({ authToken: this.user.token }),
      }
    });
  }

  initialize () {
    // This apollo client must be given to apollo components.
    // This currently ONLY uses the websocket link and does not use http.
    return new ApolloClient({
      link: this.link(),
      cache: new InMemoryCache()
    });
  }
}

export default ApolloService;
