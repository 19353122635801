import * as Sentry from '@sentry/nextjs';
import useTranslation from 'next-translate/useTranslation';
import { useState, useContext } from 'react';
import { Button, Divider } from '@arkestro/arkestro-design-system';
import { Input } from 'antd-v3';
import { useRouter } from 'next/router';
import { deeplinkLogin , sendResetPasswordEmail } from '@api/AuthApi';
import PropTypes from 'prop-types';
import { alertMessage } from 'utils/common';
import { UserContext } from 'components/providers/UserProvider/UserProvider';
import RedirectService from 'services/RedirectService';
import styles from './DeeplinkLoginForm.module.scss';

const DeeplinkLoginForm = props => {
  const { t } = useTranslation('common');
  const user = useContext(UserContext);
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const { id } = props;

  const handleForgotPassword = e => {
    e.preventDefault();
    setIsSubmitting(true);

    sendResetPasswordEmail({ email })
      .then(() => {
        alertMessage(t('forgot_password.messages.success'), 'success', 6);

        setTimeout(() => {
          setIsSubmitting(false);
          props.onModalClose();
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          setIsSubmitting(false);
          props.onModalClose();
        }, 500);
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
    deeplinkLogin({ email, password, id })
      .then(results => {
        setIsSubmitting(false);

        if (results.response && results.response.data && results.response.data.errors) {
          alertMessage(results.response.data.errors[0], 'error', 6);
          return;
        }

        if (!results.data || !results.data.current_user) {
          const stringMessage = `Email: ${email} - Status: ${results?.response?.status}`;
          const errorResponse = { ...results, email };

          Sentry.captureMessage(`Broken Login Flow - ${stringMessage}`);
          Sentry.captureException(
            new Error('Broken Login Flow', errorResponse)
          );
        }

        const { current_user: currentUser } = results.data;

        // Set the current user after successful sign in.
        user.setUserProperty({
          ...currentUser,
          hasFailedHealthCheck: false
        }, () => {
          if (!results.data.is_invited){
            router.push('/bid_requests');
          } else {
            RedirectService.redirectAuthedUser({ user: currentUser, router });
          }
        });
      })
      .catch(err => {
        setIsSubmitting(false);

        Sentry.captureMessage(`Broken Login Flow (Catch) - ${email}`);
        Sentry.captureException(err);

        alertMessage(t('login.problem_signing_in'), 'error', 6);
      });
  };

  if (!user.hasAttemptedAuth) return null;

  return (
    <>
      {!isForgotPassword && <>
        <div className={styles.title}>
          <b>{t('general.sign_in')}</b>
        </div>
        <div className={styles.subtitle}>
          {t('login.please_login')}
        </div>
        <label className={styles.inputLabel} htmlFor="user_email" >
          {t('general.email')}
        </label>
        <Input
          placeholder={t('login.enter_email')}
          size="large"
          autoFocus
          value={email}
          onChange={e => setEmail(e.target.value)}
          name="user[email]"
          id="user_email"
        />

        <label className={styles.inputLabel} htmlFor="user_password" >
          {t('general.password')}
        </label>

        <Input.Password
          placeholder={t('login.enter_password')}
          size="large"
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
          name="user[password]"
          id="user_password"
        />
      </>}
      {!isForgotPassword  &&
        <Button
          className="margin-top-medium"
          type="link"
          onClick={() => setIsForgotPassword(true)}
        >
          {t('login.forgot_password')}?
        </Button>
      }
      {isForgotPassword &&
        <>
          <div className="title" style={{ fontSize: '20px' }}>
            <b>{t('login.forgot_password')}</b>
          </div>
          <div className="title" style={{ fontSize: '15px' }}>
            {t('login.next_steps')}
          </div>
          <label htmlFor="user_password" style={{ marginTop: '15px', fontSize: '15px' }}>
            {t('general.email')}
          </label>
          <Input
            placeholder={t('login.enter_email')}
            size="large"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
            name="user[email]"
            id="user_email"
          />
        </>
      }
      <Divider/>
      { isForgotPassword ?
        <Button
          type="secondary"
          ghost
          size="large"
          onClick={() => setIsForgotPassword(false)}
        >
          {t('general.back')}
        </Button> :
        <Button
          type="secondary"
          ghost
          size="large"
          onClick={props.onModalClose}
        >
          {t('general.cancel')}
        </Button>}
      { isForgotPassword ?
        <Button
          type="secondary"
          size="large"
          onClick={handleForgotPassword}
        >
          {t('general.continue')}
        </Button> :
        <Button
          type="secondary"
          size="large"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {t('general.sign_in')}
        </Button>
      }
    </>
  );
};

DeeplinkLoginForm.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default DeeplinkLoginForm;
