import PropTypes from 'prop-types';
import { Card, Col } from '@arkestro/arkestro-design-system';
import LogoImage from 'components/LogoImage/LogoImage';

const DEFAULT_COL_SIZES = {
  xs: 24,
  sm: 16,
  md: 14,
  lg: 12,
  xl: 8
};

// Use this component as a wrapper for onboarding steps
// This creates the floating white card in the middle of the page
// Use as a child within <Row gutter={16} justify='center'>
const FloatingPanel = ({ children, colSizes }) => (
  <Col {...DEFAULT_COL_SIZES} {...colSizes}>
    <Card extra={<LogoImage imageStyle={{ height: 30 }} useDark />}>
      {children}
    </Card>
  </Col>
);

FloatingPanel.propTypes = {
  children: PropTypes.node.isRequired,
  colSizes: PropTypes.shape()
};

FloatingPanel.defaultProps = {
  colSizes: DEFAULT_COL_SIZES
};

export default FloatingPanel;
