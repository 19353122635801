import { useContext } from 'react';
import BuyerOnBoard from 'components/on_board/BuyerOnBoard';
import UserProvider, { UserContext } from 'components/providers/UserProvider/UserProvider';
import SuperAdminBar from 'components/layout/SuperAdminBar/SuperAdminBar';
import VendorOnBoard from 'components/on_board/VendorOnBoard';

const Onboarding = () => {
  const user = useContext(UserContext);
  const { category } = user;

  return (
    <UserProvider>
      <SuperAdminBar isFixed />
      {category === 'vendor' && <VendorOnBoard/>}
      {category === 'buyer' && <BuyerOnBoard/>}
    </UserProvider>
  );
};

export default Onboarding;
