import useTranslation from 'next-translate/useTranslation';
import { CSSProperties, FC } from 'react';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { combineClassNames } from '@utils/classNames';

type Props = {
  text?: string,
  style?: CSSProperties,
  iconStyle?: CSSProperties,
  textStyle?: CSSProperties,
  placeholderLength?: string | number,
  visible?: boolean,
  inline?: boolean,
  customClasses?: string[]
};

const Loading: FC<Props> = ({
  text: incomingText,
  style = {},
  iconStyle = {},
  textStyle = {},
  placeholderLength,
  visible = true,
  inline = false,
  customClasses = []
}) => {
  const { t } = useTranslation('common');
  const text = incomingText || t('general.loading', { post_fix: '...' });

  if (!visible) return null;

  if (placeholderLength) {
    return (
      <div
        className={combineClassNames('loading-container', 'placeholder', `${inline}`, customClasses)}
        style={{ width: placeholderLength }}
      />
    );
  }

  return (
    <div
      className={combineClassNames('loading-container', `${inline}`, customClasses)}
      style={style}
    >
      <div style={iconStyle}>
        <Loading3QuartersOutlined spin aria-hidden={text !== ''} />
      </div>
      <span style={{ fontSize: 14, ...textStyle }}>{text}</span>
    </div>
  );
};

export default Loading;
