import PropTypes from 'prop-types';
import { PRIVACY_POLICY_URL, TERMS_URL } from 'utils/constants';
import style from './Footer.module.scss';

const Footer = ({ collapsed }) => {
  if (collapsed) return null;

  return (
    <div id='footer'>
      <div className={style.wrapper}>
        <div className={style.policiesContainer}>
          <a
            href={PRIVACY_POLICY_URL}
            rel='noopener noreferrer'
            target='_blank'
          >
            Privacy Policy
          </a>
          <span className={style.divider}>|</span>
          <a
            href={TERMS_URL}
            rel='noopener noreferrer'
            target='_blank'
          >
            Terms of Use
          </a>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default Footer;
