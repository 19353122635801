// Segment appends "Viewed" and "Page" around these constants -- e.g. "Viewed Home Page"
// Awarding / Analysis
export const BID_ANALYSIS_PAGE_VIEWED = 'Request Analysis';
export const UPLOADED_AWARDING_SCENARIO = 'Uploaded Awarding Scenario';
export const DOWNLOADED_AWARDING_EMPTY_WORKSHEET = 'Downloaded Awarding Empty Worksheet';
export const DOWNLOADED_AWARDING_SCENARIO_WORKSHEET = 'Downloaded Awarding Scenario Worksheet';
export const DOWNLOADED_AWARDING_TABULATION = 'Downloaded Awarding Tabulation';

// Bid Requests
export const BID_REQUESTS_PAGE_VIEWED = 'Home';
export const COLUMN_SORTED = 'Column Sorted';
export const RESET = 'Reset';
export const SEARCHED_REQUESTS = 'Searched Requests';
export const SELECTED_REQUESTS_TAB = 'Selected Requests Tab';
export const CHANGED_FILTERS = 'Changed Filters';
export const SORT_DIRECTION = 'Sort Direction';
export const SORTED_REQUESTS = 'Sorted Requests';
export const TAB_NAME = 'Tab Name';
export const CHANGE_BID_REQUEST_TAB = 'Change Bid Request Tab';

// Event Categories
export const CAT_NEW_REQUEST = 'New Request';
export const CAT_REQUEST = 'Request';
export const CAT_SUPPLIER_SURVEY_QUESTIONS = 'Supplier Survey Questions';
export const CAT_LINE_ITEM_QUESTIONS = 'Line Item Questions';
export const CAT_TCO_QUESTIONS = 'TCO Questions';
export const CAT_LOTS_LINE_ITEMS = 'Lots + Line Items';
export const CAT_BID_REQUEST_TEAM_MEMBERS = 'Bid Request Team Members';
export const CAT_BID_REQUEST_SUPPLIERS = 'Bid Request Suppliers';
export const CAT_SUBMISSIONS = 'Submissions';
export const CAT_AWARD = 'Award';
export const CAT_BID_REQUEST_OVERVIEW = 'Bid Request Overview';
export const CAT_DOCUMENTS = 'Documents';
export const CAT_SURVEY = 'Survey';
export const CAT_QUOTE = 'Quote';
export const CAT_NOTIFICATIONS = 'Notifications';

// New Bid Request
export const NEW_REQUEST_FROM_MENU = 'New Request (Left Panel)';
export const NEW_REQUEST_FROM_DROPDOWN = 'New Request (Dropdown)';
export const NEW_REQUEST_SELECTS_PROPOSAL = 'Selects Request Proposals from the drop down';
export const NEW_REQUEST_SELECTS_INFORMATION = 'Selects Request Information from the drop down';
export const NEW_REQUEST_TYPE_OF_SPEND_CATEGORY = 'Type of Spend Category Selected';
export const NEW_REQUEST_CONTRACT_TYPE = 'Contract Type Selected';
export const NEW_REQUEST_APPLY_TEST_FLAG = 'Apply Test Flag';
export const NEW_REQUEST_APPLY_TEMPLATE_FLAG = 'Template';

// TCO Questionnaire
export const CREATE_TCO_SURVEY = 'Create TCO Survey';
export const EDIT_TCO_SURVEY = 'Edit TCO Survey';

// Supplier Survey Questions
export const QUESTION_ID = 'Question ID';
export const SURVEY_ID = 'Survey ID';
export const SECTION_ID = 'Survey Section ID';
export const BACK_TO_DASHBOARD = 'Back to Dashboard';
export const CREATE_SUPPLIER_SURVEY = 'Create Supplier Survey';
export const EDIT_SUPPLIER_SURVEY = 'Edit Supplier Survey';
export const CREATE_SECTION = 'Create Survey Section';
export const CHANGE_SECTION_TITLE = 'Change Section Title';
export const CHANGE_QUESTION_TITLE = 'Change Question Title';
export const CHANGE_QUESTION_TYPE = 'Change Question Type';
export const SUPPLIER_ANSWER = 'Supplier Answer';
export const SECTION_NUMBER = 'Section Number';
export const CHANGE_QUESTION_REQUIRED = 'Change Question Required';
export const DELETE_SECTION = 'Delete Section';
export const DELETE_QUESTION = 'Delete Question';
export const CREATE_QUESTION = 'Create Question';
export const PUBLISH_SCORING_SURVEY = 'Publish Scoring Survey';

// Line Item Questions
export const CREATE_LINE_ITEM_QUESTIONS_SURVEY = 'Create Line Item Questions Survey';
export const EDIT_LINE_ITEM_QUESTIONS_SURVEY = 'Edit Line Item Questions Survey';
export const CHANGE_QUALIFYING_ANSWER = 'Change Qualifying Answer';
export const SAVE_LINE_ITEM_QUESTIONS_SURVEY = 'Save Line Item Questions Survey';

// Lots + Line Items
export const CLICK_LOTS_LINE_ITEMS_TAB = 'Click Lots + Line Items Tab';
export const CHANGE_OVERALL_INCUMBENT = 'Change Overall Incumbent';
export const OVERALL_INCUMBENT_ID = 'Overall Incumbent Id';
export const CHANGE_LOT_INCUMBENT = 'Change Lot Incumbent';
export const CHANGE_LOT_NAME = 'Change Lot Name';
export const CHANGE_LOT_DESCRIPTION = 'Change Lot Description';
export const CHANGE_LINE_ITEM_FIELD = 'Change Line Item Field';
export const LINE_ITEM_CUSTOM_COLUMN_INFO = 'Line Item Custom Column Info';
export const LINE_ITEM_FIELD_NAME = 'Line Item Field Name';
export const SAVE_LOTS_AND_LINE_ITEMS = 'Save Lots and Line Items';
export const DISCARD_LOTS_AND_LINE_ITEM_CHANGES = 'Disregard Lots and Line Item Changes';
export const LOT_SEARCH_BY_TEXT = 'Lot Search By';
export const SEARCH_LOTS_AND_LINE_ITEMS = 'Search Lots and Line Items';
export const CHANGE_SEARCH_BY_LOTS_OR_LINE_ITEMS = 'Change Search By Lots or Line Items';

// Team Members
export const OPEN_INVITE_TEAM_MEMBER_DROPDOWN = 'Open Invite Team Member Dropdown';
export const CLICKED_INVITE_TEAM_MEMBER = 'Click Invite Team Member';
export const CLICKED_SEND_EMAIL_TO_TEAM_MEMBER = 'Click Send Email To Team Member';

// Bid Request Suppliers
export const CLICK_BID_REQUEST_INDIVIDUAL_SUPPLIERS = 'Click Bid Request Individual Suppliers';
export const CLICK_ADD_SUPPLIER_BY_EMAIL = 'Click Add Supplier by Email';
export const CLICK_BID_REQUEST_ORGANIZATIONS = 'Click Bid Request Individual Organizations';
export const CLICK_ADD_SUPPLIER_FROM_DATABASE = 'Click Add Supplier from Database';

// Documents
export const DOCUMENT = 'Document';
export const FILE_NAME = 'File Name';
export const CREATE_SUPPLIER_DOCUMENT = 'Create Supplier Document';
export const CREATE_INTERNAL_DOCUMENT = 'Create Internal Document';
export const UPDATE_SUPPLIER_DOCUMENT = 'Update Supplier Document';

// Submissions
export const DOWNLOAD_SUPPLIER_SCORING_SURVEY_SUBMISSIONS = 'Download Supplier Scoring Survey Submissions';
export const DOWNLOAD_LINE_ITEM_QUESTIONS_SUBMISSIONS = 'Download Line Item Questions Submissions';
export const CHANGE_SUBMISSIONS_TAB = 'Change Submissions Tab';

// Award
export const SELECT_AWARD_FORMAT = 'Select Award Format';
export const SELECT_POPULAR_SCENARIO = 'Select Popular Scenario';
export const DOWNLOAD_AWARD_WORKSHEET = 'Download Award Worksheet';
export const CREATE_AWARD_SCENARIO = 'Create Award Scenario';
export const AWARD_SCENARIO_ID = 'Award Scenario ID';
export const AWARD_FORMAT = 'Award Format';
export const POPULAR_SCENARIO = 'Popular Scenario';

// Bid Request Settings
export const CHANGE_NOTIFICATION_SETTING = 'Change Notification Setting';

// Bid Request Overview
export const DECLINE_TO_PARTICIPATE = 'Decline to Participate';

// Bid Request Survey
export const CHANGE_LINE_ITEM_ANSWER = 'Change Line Item Answer';
export const CHANGE_SCORING_SURVEY_ANSWER = 'Change Scoring Survey Answer';
export const CLICK_ASK_PROCUREMENT_TEAM_QUESTION = 'Click Ask Procurement Team Question';
export const DOWNLOAD_SCORING_SURVEY = 'Download Scoring Survey';
export const UPLOAD_SCORING_SURVEY_ANSWERS = 'Upload Scoring Survey Answers';
export const CLICK_SURVEY_NEXT_BUTTON = 'Click Survey Next Button';

// Bid Request Quote
export const RELOAD_QUOTED_PRICES = 'Reload Quoted Prices';
export const RELOAD_ALL_SUGGESTED_PRICES = 'Reload All Suggested Prices';
export const CLEAR_ALL_PRICES = 'Clear All Prices';
export const DISCARD_CHANGES = 'Discard Changes';
export const UPLOAD_QUOTE_SPREADSHEET = 'Upload Quote Spreadsheet';
export const DOWNLOAD_QUOTE_SPREADSHEET = 'Download Quote Spreadsheet';
export const TOGGLE_SHOW_DESCRIPTIVE_COLUMNS = 'Toggle Show Descriptive Columns';

// Bid Request
export const ADDED_EVALUATION_RULE = 'Added Evaluation Rule';
export const BID_REQUEST_ID = 'Request ID';
export const BID_REQUEST_CREATED = 'Created Request';
export const BID_REQUEST_SAVED = 'Updated Request';
export const BUYER_DOCUMENTS_VIEWED = 'Buyer Documents';
export const CLONED_EVENT = 'Cloned Request';
export const CREATED_REQUEST_FROM_TEMPLATE_VIEWED = 'Create Request from Template';
export const CURRENT_ROUND = 'Current Round';
export const DELETED_DOCUMENT = 'Deleted Document';
export const DOCUMENT_ID = 'Document ID';
export const DOWNLOADED_BID_TABULATION = 'Downloaded Bid Tabulation';
export const DOWNLOADED_EVENT_LOGS = 'Downloaded Event Logs';
export const DOWNLOADED_LOTS_LINE_ITEMS_CSV = 'Downloaded Lots/Line Items CSV';
export const EDIT_REQUEST_VIEWED = 'Edit Request';
export const NEW_REQUEST_VIEWED = 'New Request';
export const REMOVED_EVALUATION_RULE = 'Removed Evaluation Rule';
export const REQUEST_PARTICIPATION = 'Request Participation';
export const REQUEST_PREVIEW_VIEWED = 'Request Preview';
export const REQUEST_VENDORS_PAGE_VIEWED = 'Vendors';
export const REQUEST_BUYERS_PAGE_VIEWED = 'Collaborators';
export const STEP_NUMBER = 'Step Number';
export const STEP_TITLE = 'Step Title';
export const SUBMITTED_EVALUATION_CRITERIA_ANSWERS = 'Submitted Evaluation Criteria Answers';
export const SAVED_BID_AS_DRAFT = 'Saved Bid as Draft';
export const TEMPLATE_ID = 'Template ID';
export const TOGGLED_REQUIRED_DOCUMENT = 'Toggled Document Required';
export const UPLOADED_DOCUMENTS = 'Uploaded Documents';
export const UPLOADED_LOTS_LINE_ITEMS_CSV = 'Uploaded Lots/Line Items CSV';
export const UPLOADED_FLAT_FILE_IMPORT = 'Uploaded using Flat File Import';
export const VENDORS_DOCUMENTS_VIEWED = 'Supplier Documents';
export const SUBMITTED_DOCUMENTS = 'Vendor Submitted Documents';
export const VENDOR_SURVEY_VIEWED = 'Vendor Survey';
export const VIEWED_DOCUMENT = 'Viewed Document';
export const USE_BASELINE_PRICES_AS_SUGGESTED_PRICES = 'Use Baseline Prices as Suggested Prices';

// Bid Request Collaborators
export const CREATED_BUYER = 'Created Buyer';
export const DELETED_BUYER = 'Deleted Buyer';
export const DOWNLOADED_SAMPLE_BUYERS_AS_CSV = 'Downloaded Sample Buyers CSV';
export const INVITED_BUYERS = 'Invited Buyers';
export const REMOVED_BUYER = 'Removed Buyer';
export const UPLOADED_BUYERS_AS_CSV = 'Uploaded Buyers as CSV';

// Bid Request Scorers
export const INVITED_SCORERS = 'Invited Scorers';
export const SCORER_ID = 'Scorer ID';
export const SCORER_IDS = 'Scorer Ids';
export const DELETED_SCORER = 'Deleted Scorer';

// Bid Request Suppliers
export const UPLOADED_VENDORS_AS_CSV = 'Uploaded Vendors as CSV';
export const DOWNLOADED_SAMPLE_VENDORS_AS_CSV = 'Downloaded Sample Vendors CSV';
export const FILTERED_INDIVIDUAL_VENDORS = 'Filtered Individual Vendors';
export const FILTERED_VENDOR_ORGANIZATIONS = 'Filtered Vendor Organizations';
export const SEARCHED_INDIVIDUAL_VENDOR = 'Searched for Individual Vendor';
export const SEARCHED_VENDOR_ORGANIZATION = 'Searched for Vendor Organization';

// Bid Request Lots
export const COLLAPSED_LOT = 'Collapsed Lot';
export const CREATED_LOT = 'Created Lot';
export const DELETED_LOT = 'Deleted Lot';
export const LOT_ID = 'Lot ID';
export const LOT_INDEX = 'Lot Index';
export const LOTS_LINE_ITEMS_VIEWED = 'Lots/Line Items';
export const RESET_PRICING_DATA = 'Reset Pricing Data';
export const TOGGLED_LOT_LEVEL_NO_BID = 'Toggled Lot-Level No Bid';
export const TOGGLED_QUANTITIES_PRICE_VIEW = 'Toggled Quantities/Price View';
export const TOGGLED_VIEWING_SUGGESTED_BID_PRICE = 'Toggled Viewing Suggested Bid Price';
export const UNDO_RESET_PRICING_DATA = 'Undo Reset Pricing Data';
export const UPDATED_LOT = 'Updated Lot';

// Bid Request Line Items
export const APPLIED_DISCOUNT_TO_SELECTED_LINE_ITEMS = 'Applied Discount to Selected Line Items';
export const CREATED_LINE_ITEM = 'Created Line Item';
export const DELETED_LINE_ITEM = 'Deleted Line Item';
export const DISCOUNT_PERCENTAGE = 'Discount Percentage';
export const ENABLED = 'Enabled';
export const LINE_ITEM_ID = 'Line Item ID';
export const LINE_ITEM_INDEX = 'Line Item Index';
export const NEW_VIEW_STATE = 'New View State';
export const PRICE = 'Price';
export const QUANTITIES = 'Quantities';
export const REQUIRED = 'Required';
export const TOGGLED_LINE_ITEM_LEVEL_NO_BID = 'Toggled Line Item-Level No Bid';
export const TYPE = 'Type';
export const VIEWED_LINE_ITEM_PREVIEW = 'Viewed Line Item Preview';

// Bid Response
export const BID_RESPONSE_ID = 'Response ID';
export const BID_RESPONSE_PAGE_VIEWED = 'Request Dashboard';
export const SUBMITTED_BID = 'Submitted Bid';

// Buyer
export const BUYER_ORG_ID = 'Buyer Org ID';

// Supplier Bid Responses
export const VENDOR_BID_PAGE_VIEWED = 'Submit Bid';

// Supplier
export const COPIED_INDIVIDUAL_VENDOR_PROFILE_LINK = 'Copied Individual Vendor Profile Link';
export const CREATED_VENDOR = 'Created Vendor';
export const DELETED_VENDOR = 'Deleted Vendor';
export const EDITED_INDIVIDUAL_VENDOR_NOTES = 'Edited Individual Vendor Notes';
export const EDIT_PROFILE_VIEWED = 'Edit Profile';
export const INVITED_VENDORS = 'Invited Vendors';
export const PROFILE_ID = 'Profile ID';
export const PROFILE_IDS = 'Profile IDs';
export const STARTED_INDIVIDUAL_VENDOR_CONVERSATION = 'Started Individual Vendor Conversation';
export const VENDOR_ID = 'Vendor ID';
export const VENDOR_INDIVIDUAL_PROFILE_VIEWED = 'Vendor Individual Profile';

// Supplier Organizations
export const COPIED_VENDOR_ORG_PROFILE_LINK = 'Copied Vendor Organization Profile Link';
export const CREATED_VENDOR_ORGANIZATION = 'Created Vendor Organization';
export const DELETED_VENDOR_ORGANIZATION = 'Deleted Vendor Organization';
export const EDITED_VENDOR_ORGANIZATION_NOTES = 'Edited Vendor Organization Notes';
export const EDITED_VENDOR_ORGANIZATION_NAME = 'Edited Vendor Organization Name';
export const VENDOR_ORG_ID = 'Vendor Org ID';
export const VENDOR_ORGANIZATION_PROFILE_VIEWED = 'Vendor Organization Profile';

// Conversations
export const CONVERSATION_ID = 'Conversation ID';
export const CONTEXT_PICKER_VIEWED = 'Conversations Context Picker';
export const CREATED_NEW_CONVERSATION = 'Created New Conversation';
export const GENERAL_COMMUNICATION_VIEWED = 'General Communication';
export const NUMBER_OF_PARTICIPANTS = 'Number of Participants';
export const NUMBER_OF_INVITATIONS = 'Number of Invitations';
export const REQUEST_COMMUNICATION_VIEWED = 'Request Communication';
export const SENT_MESSAGE = 'Sent Message';
export const VIEWED_CONVERSATION = 'Viewed Conversation';

// Buyer Onboarding
export const BUYER_WELCOME_VIEWED = 'Buyer Welcome';
export const BUYER_INTRO_DETAILS_VIEWED = 'Buyer Intro Details';
export const BUYER_OVERVIEW_STEP_VIEWED = 'Buyer Overview Step';
export const BUYER_SPEND_CATEGORIES_VIEWED = 'Buyer Spend Categories';
export const SKIPPED_BUYER_ONBOARDING_STEP = 'Skipped Buyer Onboarding Step';

// Supplier Onboarding
export const VENDOR_INTRO_DETAILS_VIEWED = 'Viewed Vendor Intro Details Page';
export const VENDOR_OVERVIEW_STEP_VIEWED = 'Viewed Vendor Overview Step Page';
export const VENDOR_DOMAIN_MATCH_FAILED_VIEWED = 'Viewed Domain Match Failed Page';
export const VENDOR_CONFIRM_ORGANIZATION_VIEWED = 'Viewed Confirm Vendor Organization Page';
export const VENDOR_JOINED_ORGANIZATION = 'Joined Vendor Organization';
export const VENDOR_CREATE_ORGANIZATION_VIEWED = 'Viewed Create Vendor Organization Profile Page';
export const VENDOR_CREATED_ORGANIZATION_PROFILE = 'Created Vendor Organization Profile';
export const VENDOR_WELCOME_VIEWED = 'Viewed Vendor Welcome Page';
export const VENDOR_SKIPPED_OVERVIEW_STEPS = 'Skipped Vendor Onboarding Step';

// Suppliers Database
export const OPTED_IN_TO_PUBLIC_DB = 'Opted In to Public DB';
export const REGISTERED_ORGANIZATION_SUPPLIER_DB = 'Registered for Organization Supplier DB';
export const REGISTERED_PUBLIC_SUPPLIER_DIRECTORY = 'Registered for Public Supplier Directory';
export const SENT_VENDOR_DB_EMAIL_INVITATION = 'Sent Vendor DB Email Invitation';
export const VENDOR_DATABASE_VIEWED = 'Vendors';
export const VENDOR_ORGANIZATIONS_VIEWED = 'Organizations';

// Corporate

// Suppliers
export const CORPORATE_SUPPLIERS = 'Corporate Suppliers';
export const SEARCHED_CORPORATE_SUPPLIERS = 'Searched for Corporate Suppliers';
export const SENT_CORPORATE_SUPPLIER_DB_EMAIL_INVITATION = 'Sent Corporate Supplier DB Email Invitation';

// Team
export const BUYER_ID = 'Buyer ID';
export const MY_TEAM_VIEWED = 'My Team';
export const SENT_BUYER_TEAM_INVITATION_EMAIL = 'Sent Buyer Team Invitation Email';

// User Account
export const EDITED_PROFILE = 'Edited Profile';
export const UPDATED_ACCOUNT_INFORMATION = 'Updated Account Information';
export const USER_IDS = 'User Ids';

// UI
export const COLLAPSED = 'Collapsed';
export const EXPANDED = 'Expanded';
export const NEW_DISPLAY_STATE = 'New Display State';
export const OPENED_ACCOUNT_MENU = 'Opened Account Menu';
export const OPENED_HELP_WIDGET = 'Opened Help Widget';
export const TOGGLED_NAVIGATION_BAR_DISPLAY = 'Toggled Navigation Bar Display';

// Notifications
export const DISMISSED_ALL_NOTIFICATIONS = 'Dismissed All Notifications';
export const DISMISSED_SPECIFIC_NOTIFICATION = 'Dismissed Notification';
export const CLICKED_NOTIFICATION = 'Clicked Notification';
export const NOTIFICATION_TYPE = 'type';
export const DELIVERY_METHOD = 'Delivery Method';
export const NOTIFICATION_EVENT = 'Notification Event';

// SendGrid
export const BOUNCED_USER_ID = 'bouncedUserId';
export const SENT_TEST_INVITE = 'Sent Test Invite Email';
export const TEST_EMAIL = 'Test Email';

// General
export const BUYER = 'Buyer';
export const EMAIL = 'Email';
export const FILTER_VALUE = 'Filter Value';
export const OPTIONS = 'Options';
export const PRIVACY_POLICY_VIEWED = 'Privacy Policy';
export const REQUESTED_HELP = 'Requested Help';
export const SEARCH_VALUE = 'Search Value';
export const SORTING_OPTIONS = 'Sorting Options';
export const TERMS_OF_USE_VIEWED = 'Terms of Use';
export const VENDOR = 'Vendor';

// Unused - To Be Deleted or Used
export const CREATED_VENDOR_ORGANIZATION_PROFILE = 'Created Vendor Organization Profile';
export const COPIED_VENDOR_INVITE_LINK = 'Copied Vendor Invitation Link';
export const CURRENT_PAGE_INDEX = 'Current Page Index';
export const JOINED_VENDOR_ORG = 'Joined Vendor Organization';
export const NEW_PAGE_INDEX = 'New Page Index';
export const PAGE_NAVIGATION_ACTION = 'Page Navigation Action';
export const SKIPPED_VENDOR_ONBOARDING_STEP = 'Skipped Vendor Onboarding Step';

// Recommendations
export const RECOMMENDATIONS = 'Recommendations';
export const RECOMMENDATION_VIEWED = 'Recommendation';
export const RECOMMENDATION_ID = 'Recommendation ID';
export const RECOMMENDATIONS_SORTED = 'Sorted Recommendations';
export const RECOMMENDATIONS_SEARCHED = 'Searched Recommendations';
export const RECOMMENDATION_OPTED_IN = 'Recommendation Opted-In';
export const RECOMMENDATION_OPTED_OUT = 'Recommendation Opted-Out';
export const RECOMMENDATION_LIKED = 'Recommendation Liked';
export const RECOMMENDATION_DISLIKED = 'Recommendation Disliked';

// General
export const CATEGORY_ID = 'Category ID';
export const CATEGORY_TYPE = 'Category Type';
export const CONTRACT_TYPE = 'Contract Type';
export const ONE_TIME_PURCHASE = 'One-Time Purchase';
export const ONGOING_CONTRACT = 'Ongoing Contract';
export const VALUE = 'Value';

// Data Quality
export const DATA_QUALITY_PAGE = 'Data Quality';
