import Content from './Content/Content';
import SideMenu from './SideMenu/SideMenu';

const Layout = ({ children }) => (
  <>
    <SideMenu />
    <Content>
      {children}
    </Content>
  </>
);

export default Layout;
