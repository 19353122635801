type ClassValue = string | ClassValue[];

export const combineClassNames = (...args: ClassValue[]): string => {
  return args
    .flatMap(arg => {
      if (typeof arg === 'string') {
        return arg;
      }
      if (Array.isArray(arg)) {
        return combineClassNames(...arg);
      }
      return [];
    })
    .join(' ');
};
