import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@arkestro/arkestro-design-system';
import { UserSwitchOutlined } from '@ant-design/icons';
import Routes from '@services/Routes';
import { impersonate } from 'api/AuthApi';
import { alertMessage } from 'utils/common';
import { combineClassNames } from 'utils/classNames';
import { truncateText } from '@utils/helpers';

import style from './SettingsDisplay.module.scss';

const ImpersonateEmail = ({ id, email }) => {
  const linkRef = useRef(null);
  const [title, setTitle] = useState();
  const [placement, setPlacement] = useState('left');
  const [disabled, setIsDisabled] = useState(false);

  const handleImpersonate = () => {
    if (disabled) return;

    setIsDisabled(true);

    impersonate({ user_id: id }).then(results => {
      if (results?.response?.data?.errors) {
        setIsDisabled(false);
        alertMessage(results.response.data.errors[0], 'error', 6);
        return;
      }

      alertMessage('Switched Users');
      setIsDisabled(false);

      window.location = Routes.ROOT_AUTHENTICATED_PATH;
    })
      .catch(() => {
        setIsDisabled(false);
        alertMessage('There was an error while trying to switch users to impersonate.', 'error', 6);
      });
  };

  useEffect(() => {
    const { offsetWidth, scrollWidth } = linkRef?.current || {};

    if (offsetWidth < scrollWidth) setTitle(`Impersonate ${email}`);
    if (offsetWidth > 200) setPlacement('bottom');
  }, [linkRef?.current, email]);

  return (
    <Button
      type='text'
      ref={linkRef}
      onClick={handleImpersonate}
      disabled={disabled}
    >
      <Tooltip title={title} placement={placement}>
        <UserSwitchOutlined className={combineClassNames(style.settingsIcon, 'icon-red')} />
        {truncateText(email, 17)}
      </Tooltip>
    </Button>
  );
};

ImpersonateEmail.propTypes = {
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default ImpersonateEmail;
