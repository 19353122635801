import { ReactNode } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { matchesSVG } from 'utils/strings';

interface Props {
  avatar: ReactNode;
  name: ReactNode;
  summary?: string;
}

export const MediaDisplay = ({ avatar, name, summary }: Props) => {
  const isSvg = matchesSVG(avatar);

  return (
    <div className='media-display-container'>
      <div className='avatar-container large'>
        {isSvg ?
          <img src={avatar as string} alt='Logo' className='avatar'/> :
          <UserOutlined className='avatar' />
        }
      </div>
      <div className='body'>
        <div className='name'>{name}</div>
        {!!summary && <div className='summary'>{summary}</div>}
      </div>
    </div>
  );
};
