import { ReactNode } from 'react';
import { combineClassNames } from '@utils/classNames';
import { safeKeyPress } from 'utils/accessibility';

// TODO: Convert to ADS Button
interface IconButtonProps {
  onClick?: () => void;
  isActive?: boolean;
  children: ReactNode;
  title?: string;
  additionalClassNames?: string[];
}

export const IconButton = ({
  isActive = false,
  onClick,
  children,
  title = '',
  additionalClassNames = [],
  ...props
}: IconButtonProps) => (

  <div
    className={combineClassNames('icon-button', `${isActive}`, additionalClassNames)}
    onClick={onClick}
    onKeyPress={e => safeKeyPress(e, onClick)}
    role='button'
    tabIndex={0}
    {...props}
  >
    <div className='icon-wrapper'>{children}</div>
    {title && <span>{title}</span>}
  </div>
);
