export const toSentence = (array: string[]) => {
  if (!array || array.length === 0) return '';
  const firstWords = array.slice(0, array.length - 1);
  const lastWord = array.slice(-1).toString();

  if (array.length === 1) return lastWord;

  return `${firstWords.join(', ')}, and ${lastWord}`;
};

/**
 * Check if case-insensitive "new" string exists in another string. Number would return no match.
 * @param {String|Number} string
 * @return {Boolean} Whether there's a match or not
 */
export const matchesNew = (string = '') => !!`${string}`.match(/new/i);

export const matchesSVG = (str: string) => `${str}`.includes('.svg');

export const toString = (value: any) => {
  if (value === null || value === undefined) return '';
  if (typeof value === 'string') return value;
  if (Array.isArray(value) || typeof value === 'object') {
    return JSON.stringify(value);
  }
  return value.toString();
};

export const toLowerCaseAndTrim = (str: string) => {
  if (!str?.length) return '';
  return str.toLowerCase().trim();
};

export const DEFAULT_TRUNCATION = 19;

export const truncate = (text: string | undefined, length = DEFAULT_TRUNCATION, suffix = '...') => {
  if (!text?.length || length <= 0 || text.length < length) return text;
  return `${text.substring(0, length)}${suffix}`;
};
