import { Translate } from 'next-translate';

import {
  BidAcceptanceInviteNotification,
  SupplierDatabaseInviteNotification
} from '@components/layout/Header/Notifications/types';

export const mapCompanyInviteToNotification = (t: Translate, invite: SupplierDatabaseInviteNotification) => ({
  action_name: 'company_invitation',
  activity_id: invite.id,
  title: t('notifications.supplier_database_invitation'),
  created_at: invite.created_at,
  is_seen: false,
  is_read: false,
  actions_object: {
    accepted_company_invite: {
      title: t('general.accept'),
      action: ''
    },
    rejected_company_invite: {
      title: t('general.decline'),
      action: ''
    },
  },
  additional_info: {},
});

export const mapRequestInviteToNotification = (t: Translate, invite: BidAcceptanceInviteNotification) => ({
  action_name: 'request_invitation',
  activity_id: invite.id,
  title: t('notifications.request_invitation'),
  created_at: invite.created_at,
  is_seen: false,
  is_read: false,
  actions_object: {
    accepted_request_invite: {
      title: t('general.accept'),
      action: ''
    },
    rejected_request_invite: {
      title: t('general.decline'),
      action: ''
    },
  },
  additional_info: {},
});
