import { Dispatch, SetStateAction } from 'react';
import useTranslation from 'next-translate/useTranslation';

import NotificationsListItem from '@components/layout/Header/Notifications/NotificationsListItem/NotificationsListItem';
import { Notification } from '@components/layout/Header/Notifications/types';
import { dateSort } from '@utils/sorting';

import style from './NotificationsListGroup.module.scss';

type Props = {
  title: string;
  list: Notification[];
  setAllList: Dispatch<SetStateAction<Notification[]>>;
  allList: Notification[];
};

const NotificationsListGroup = ({ title = '', list = [], setAllList, allList }: Props): JSX.Element => {
  const { t } = useTranslation('common');
  const validList = list.filter(item => item.activity_id);
  const totalItems = validList.length;
  const sortedList = dateSort(validList, 'created_at', 'desc');

  return (
    <div className={style.group} data-testid='notifications-group'>
      <div className={style.header}>
        <h2 className={style.title} data-testid='notifications-group-title'>{title}</h2>
        <p
          className={style.count}
          title={t('notifications.total_notifications', { count: totalItems })}
          data-testid='notifications-group-count'
        >
          ({totalItems})
        </p>
      </div>
      {sortedList.length > 0 && sortedList.map((item: Notification) => (
        <NotificationsListItem
          key={`notification-${item.activity_id}`}
          item={item}
          allList={allList}
          setAllList={setAllList}
        />
      ))}
    </div>
  );
};

export default NotificationsListGroup;
