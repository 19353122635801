import { attachTrackingAnalytics } from '@services/SegmentService';
import Routes from 'services/Routes';
import { DISMISSED_ALL_NOTIFICATIONS } from './analytics_constants';
import { ASSETS_URL } from './constants';

/**
 * @param target {String} Target location URL
 * @param router {Object} React router router object from calling component
 * @param forceReload {Boolean} Sets whether or not to force a reload of the page.
 */
const handleNav = (target, router, forceReload = false) => {
  const getRequest = url => url.match(/bid_requests\/\d+/)?.[0];
  const currentRequest = getRequest(window.location.pathname);
  const targetRequest = getRequest(target);

  const needsReload =
    forceReload ||
    (currentRequest && targetRequest) && currentRequest !== targetRequest ||
    target.includes('format') ||
    target.includes('schedule');

  if (needsReload){
    window.location.assign(target);
  } else {
    router.push(target);
  }
};

const viewRequestFormat = ({ bid_request_id }, router) => {
  const navUrl = `/bid_requests/${bid_request_id}/schedule`;

  handleNav(navUrl, router);
};

const viewRequestDashboard = ({ bid_request_id }, router) => {
  const navUrl = `/bid_requests/${bid_request_id}/dashboard`;

  handleNav(navUrl, router);
};

const viewRequestVendorsTab = ({ bid_request_id }, router) => {
  const navUrl = `/bid_requests/${bid_request_id}/suppliers`;

  handleNav(navUrl, router);
};

const viewRequestDocumentsTab = ({ bid_request_id }, router) => {
  const navUrl = `/bid_requests/${bid_request_id}/documents`;

  handleNav(navUrl, router);
};

const viewVendorSurvey = ({ bid_request_id }, router) => {
  const navUrl = `/bid_requests/${bid_request_id}/format/evaluation`;

  handleNav(navUrl, router);
};

const viewSuggestedPrices = ({ bid_request_id }, router) => {
  const navUrl = `/bid_requests/${bid_request_id}/format/preview`;

  handleNav(navUrl, router);
};

const viewAwardTab = ({ bid_request_id }, router) => {
  const navUrl = `/bid_requests/${bid_request_id}/award`;

  handleNav(navUrl, router);
};

const viewSupplierSurveyAsSupplier = ({ bid_request_id }, router) => {
  const navUrl = `/bid_requests/${bid_request_id}/bid_submission`;

  handleNav(navUrl, router, true);
};

const viewTeamManagementWithEmail = ({ user_email }, router) => {
  const navUrl = `/team?search=${user_email}`;

  handleNav(navUrl, router);
};

const viewVendorsTabWithEmail = ({ user_email }, router) => {
  const navUrl = `/suppliers?search=${user_email}`;

  handleNav(navUrl, router);
};

// These variables are defined on the additional_data from the Ruby template
const viewQuoteSubmission = ({ bid_request_id }, router) => {
  const navUrl = Routes.QUOTE_SUBMISSION_PATH(bid_request_id);

  handleNav(navUrl, router);
};

export const markRead = async (feed, activityId) => {
  await feed.get({ mark_read: [activityId] });
};

/**
 * Marks a group of activities as 'seen', this will decrement the count of unseen
 * @param feed {StreamFeed} feed for this user
 * @param activityIds {Array.<String>} Array of activity ids
 * @returns {Number}
 */
export const markSeen = async (feed, activityIds) => {
  if (activityIds.length > 0) {
    await feed.get({ mark_seen: activityIds });
  }
  return activityIds.length;
};

export const markAllSeen = async feed => {
  await feed.get({ mark_seen: true });
};

export const markAllRead = async feed => {
  await feed.get({ mark_read: true });
  attachTrackingAnalytics(DISMISSED_ALL_NOTIFICATIONS);
};

/**
 * Map for mapping an incoming notification to an appropriate action function
 * @type {Object.<String, Function>}
 */
export const notificationActionsMap = {
  'viewRequestFormat' : viewRequestFormat,
  'viewRequestDashboard' : viewRequestDashboard,
  'viewRequestDocumentsTab' : viewRequestDocumentsTab,
  'viewRequestVendorsTab' : viewRequestVendorsTab,
  'viewVendorSurvey' : viewVendorSurvey,
  'viewSuggestedPrices' : viewSuggestedPrices,
  'viewAwardTab' : viewAwardTab,
  'viewSupplierSurveyAsSupplier' : viewSupplierSurveyAsSupplier,
  'viewTeamManagementWithEmail' : viewTeamManagementWithEmail,
  'viewVendorsTabWithEmail' : viewVendorsTabWithEmail,
  'viewQuoteSubmission': viewQuoteSubmission,
  'markRead' : markRead,
  'markSeen' : markSeen
};

/**
 * Maps incoming notification action to images
 * @type {Object.<String, *>}
 */
export const notificationImageMap = {
  'viewRequestFormat' : <img src={`${ASSETS_URL}/images/time.svg`} alt='Time' style={{ width: 40, height: 40 }}/>,
  'viewRequestVendorsTab' : <img
    src={`${ASSETS_URL}/images/error-warning.svg`}
    alt='Warning'
    style={{ width: 40, height: 40 }}
  />,
  'viewRequestDocumentsTab': <img
    src={`${ASSETS_URL}/images/document-received.svg`}
    alt='Document Received'
    style={{ width: 40, height: 40 }}
  />,
  'viewVendorSurvey' : <img
    src={`${ASSETS_URL}/images/error-warning.svg`}
    alt='Warning'
    style={{ width: 40, height: 40 }}
  />,
  'viewSuggestedPrices' : <img
    src={`${ASSETS_URL}/images/error-warning.svg`}
    alt='Warning'
    style={{ width: 40, height: 40 }}
  />,
  'viewTeamManagementWithEmail' : <img
    src={`${ASSETS_URL}/images/message-error.svg`}
    alt='Warning'
    style={{ width: 40, height: 40 }}
  />,
  'viewVendorsTabWithEmail' : <img
    src={`${ASSETS_URL}/images/message-error.svg`}
    alt='Warning'
    style={{ width: 40, height: 40 }}
  />,
  'viewSupplierSurveyAsSupplier' : <img
    src={`${ASSETS_URL}/images/survey.svg`}
    alt='Survey'
    style={{ width: 40, height: 40 }}
  />,
  'viewAwardTab' : <img
    src={`${ASSETS_URL}/images/crown.svg`}
    alt='Survey'
    style={{ width: 40, height: 40 }}
  />,
};
