const COLLAPSED_KEY = 'bidops.layout.collapsed';
const ERROR_MSG = 'Please try a browser that supports localStorage to persist layout configuration.';

const isInBrowser = typeof window !== 'undefined';

export const storeCollapsed = isCollapsed => {
  if (!isInBrowser) return;

  try {
    const state = isCollapsed ? 'collapsed' : 'expanded';

    return window.localStorage.setItem(COLLAPSED_KEY, state);
  }

  catch (error) {
    window.console.warn(ERROR_MSG);
    return undefined;
  }
};

export const isCollapsedFromStorage = () => {
  if (!isInBrowser) return false;

  try {
    return window.localStorage.getItem(COLLAPSED_KEY) === 'collapsed';
  }
  catch (error) {
    return true;
  }
};
