import { ReactNode } from 'react';

interface Props {
  children?: ReactNode,
  icon?: ReactNode,
  label: string,
};

export const FirmographicLabel = ({ label, children, icon }: Props) => {
  return (
    <div className='firmographic-label-container'>
      {!!icon && (
        <div className='icon-container'>
          {icon}
        </div>
      )}
      <div className='body'>
        <div className='label'>{label}</div>
        {!!children && <div className='child'>{children}</div>}
      </div>
    </div>
  );
};
